import React, {FunctionComponent} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Label} from "reactstrap";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import {ColorType} from "../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../utils/dateUtils";
import Icon from "../../icon/Icon";
import {CreditNoteStateMap, InvoiceState} from "../../../interfaces/InvoiceInterfaces";
import {fileUtils} from "../../../utils/fileUtils";
import {invoiceService} from "../../../services/InvoiceService";
import PageTitle from "./PageTitle";
import {INVOICES_PATH} from "../../../constants/routes/RoutePaths";
import {InvoiceHeaderProps} from "./InvoiceHeader";
import {Link, useNavigate} from "react-router-dom";
import {creditNoteService} from "../../../services/CreditNoteService";

const CreditNoteHeader: FunctionComponent<InvoiceHeaderProps> = ({
  className = "",
  invoice,
  status,
  onClickAdd,
  onUpdateStatus
}) => {
  const intl = useIntl()

  const onClickDownload = () => fileUtils.downloadFile(creditNoteService.downloadCreditNote(invoice.id), {
    name: invoice.invoiceNumber,
    extension: "pdf"
  }, intl);

  const navigate = useNavigate();

  const onClickValidate = () =>  invoiceService.manageTransition(invoice.id).then(onUpdateStatus);

  const onDelete = () => creditNoteService.deleteCreditNote(invoice.id).then(() => navigate(INVOICES_PATH));


  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        <PageTitle
          pageTitle={invoice?.invoiceNumber || "ACE" + invoice.date.split("-")[0].split("20")[1] + "-XXXXX"}
          className="me-3"
          breadcrumbItems={[{
            title: intl.formatMessage({id: "credit_note_header_title"}),
            to: INVOICES_PATH
          }]}
        />
        <div>
          <Label className="me-1"><FormattedMessage id="order_status" /> :</Label>
          <Badge color={CreditNoteStateMap[status].color} pill>{intl.formatMessage({id: CreditNoteStateMap[status].label})}</Badge>
        </div>

        <div className="ms-auto">
          {invoice?.status !== InvoiceState.BILLED &&
            <Button color="secondary" className="me-2" onClick={onClickAdd}>
              <FormattedMessage id="credit_note_billing_item_title_add"/>
            </Button>
          }
          <Button color={ColorType.SECONDARY} onClick={onClickDownload} className="border border-dark">
            <FormattedMessage id="credit_note_download"/>
            <Icon name="Download" className="ms-2"/>
          </Button>
          {invoice?.status === InvoiceState.DRAFT &&
            <>
              <Button className="ms-2" color={ColorType.DANGER} onClick={onDelete}>
                <FormattedMessage id="credit_note_delete" />
                <Icon name="Trash" className="ms-2" />
              </Button>

              <Button className="ms-2" color={ColorType.PRIMARY} onClick={onClickValidate}>
                <FormattedMessage id="credit_note_validate"/>
                <Icon name="Download" className="ms-2"/>
              </Button>
            </>
          }
        </div>
      </div>

      <div className="d-flex">
        <div className="me-2">
          <Label className="me-1"><FormattedMessage id="purchase_order_creation"/></Label>
          {dateUtils.formatDateDayJs(invoice?.date)}
        </div>
        {invoice?.linkedInvoiceId &&
          <div className="me-2">
            <Link to={`${INVOICES_PATH}/${invoice.linkedInvoiceId}`}><FormattedMessage
              id="go_to_invoice"/></Link>
          </div>
        }
      </div>
    </div>
  )
}

export default CreditNoteHeader
