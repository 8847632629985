import React, {FunctionComponent, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {DropdownItem, Label} from "reactstrap";
import Badge from "../../atoms/Badge";
import Button from "../../atoms/Button";
import {ColorType, IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {dateUtils} from "../../../utils/dateUtils";
import Icon from "../../icon/Icon";
import {Invoice, InvoiceState, InvoiceStateMap} from "../../../interfaces/InvoiceInterfaces";
import {fileUtils} from "../../../utils/fileUtils";
import {invoiceService} from "../../../services/InvoiceService";
import PageTitle from "./PageTitle";
import {CREDIT_NOTES_PATH, INVOICES_PATH} from "../../../constants/routes/RoutePaths";
import {Link, useNavigate} from "react-router-dom";
import ModalCreditNoteConfirm from "../modal/ModalCreditNoteConfirm";
import ButtonGroup from "../button/ButtonGroup";
import ModalRemindInvoiceCustomer from "../modal/ModalRemindInvoiceCustomer";
import {toastUtils} from "../../../utils/toastUtils";
import {customerService} from "../../../services/CustomerService";
import {RemindInvoiceCustomerMailBody} from "../../../interfaces/CustomerInterfaces";

export interface InvoiceHeaderProps {
  className?: string,
  invoice: Invoice,
  status: InvoiceState,
  onUpdateStatus: (invoice: Invoice) => void
  onClickAdd: () => void,
  onClickContactEdit: () => void,
  displayStatus?: boolean,
  isCustomer?: boolean,
}

const InvoiceHeader: FunctionComponent<InvoiceHeaderProps> = ({
  className = "",
  invoice,
  status,
  onClickAdd,
  onClickContactEdit,
  onUpdateStatus,
  displayStatus = false,
  isCustomer = false,
}) => {
  const intl = useIntl()
  const navigate = useNavigate();
  const invoiceNumber = invoice?.invoiceNumber || "FC" + invoice.date.split("-")[0].split("20")[1] + "-XXXXX";

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openRemindInvoiceCustomerModal, setOpenRemindInvoiceCustomerModal] = useState<boolean>(false);
  const todayDate = dateUtils.formatDateYYYYMMDD(dateUtils.today());
  const daysBetweenLimitPaymentDateAndToday = dateUtils.getDaysArrayBetweenDates(invoice.limitPaymentDate, todayDate).length;

  const onClickDownloadVouchers = () => {
    invoiceService.downloadLinkedVouchers(invoice.id)
      .then(file => fileUtils.saveFile(file, `${invoiceNumber}_vouchers.zip`))
      .catch((error) => {
        if (error?.status === 400) {
          toastUtils.infoToast(intl.formatMessage({id: "activity_billing_no_voucher_found"}))
        } else {
          toastUtils.errorToast(intl.formatMessage({id: "activity_bill_download_failure"}))
        }
      })
  }

  const onClickDownloadPdf = () => fileUtils.downloadFile(invoiceService.downloadInvoices(invoice.id), {
    name: invoice.invoiceNumber,
    extension: "pdf"
  }, intl);

  const onClickDownloadReminder = () => fileUtils.downloadFile(invoiceService.downloadInvoiceReminder(invoice.id), {
    name: invoice.invoiceNumber,
    extension: "pdf"
  }, intl);

  const onClickValidate = () => {
    invoiceService.manageTransition(invoice.id)
      .then(onUpdateStatus)
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "error_toast_update_invoice"})));
  };
  const onDelete = () => invoiceService.deleteInvoice(invoice.id).then(() => navigate(INVOICES_PATH));
  const handleSendMail = (request: RemindInvoiceCustomerMailBody) => {
    setOpenRemindInvoiceCustomerModal(false)
    customerService.remindInvoiceCustomerMail(invoice.billingCustomer.customerId, {template: request.template, reminderPdf: request.reminderPdf, invoiceId: request.invoiceId})
      .then(() => {
        toastUtils.successToast(intl.formatMessage({id: "invoice_mail_remind_customer_toast_success"}))
      })
      .catch(() => toastUtils.errorToast(intl.formatMessage({id: "invoice_mail_remind_customer_toast_error"})));
  }

  const PaidStatus = (): JSX.Element => (
    <div className="color-primary">
      <Label className="me-1"><FormattedMessage id="invoice_header_date_fully_paid"/></Label>
      {dateUtils.formatDateDayJs(invoice.fullyPaidDate)}
    </div>
  );

  const BilledStatus = (): JSX.Element => (
    <div className="color-warning">
      <Label className="me-1"><FormattedMessage id="invoice_header_date_remaining"/></Label>
      {dateUtils.formatDateDayJs(invoice.limitPaymentDate)}
    </div>
  );

  const LateStatus = (): JSX.Element => (
    <div className="d-flex">
      <div className="color-danger">
        <Label className="me-1"><FormattedMessage id="invoice_header_date_remaining"/></Label>
        {dateUtils.formatDateDayJs(invoice.limitPaymentDate)}
        <Label className="ms-1"><FormattedMessage id="invoice_header_date_late_payment" values={{days: daysBetweenLimitPaymentDateAndToday}}/></Label>
      </div>
      <div className="ms-5">
        <Label className="me-1"><FormattedMessage id="invoice_header_remind_customer"/></Label>
        <Icon name="Email" color={ColorType.SECONDARY} size={IconSizeType.SM} className="cursor-pointer me-4" onClick={() => setOpenRemindInvoiceCustomerModal(true)}/>
      </div>
    </div>
  );

  const statusDetails = {
    [InvoiceState.PAID]: <PaidStatus />,
    [InvoiceState.BILLED]: <BilledStatus />,
    [InvoiceState.LATE]: <LateStatus />,
  };


  return (
    <div className={className}>
      <div className="d-flex align-items-center">
        <PageTitle
          pageTitle={invoiceNumber}
          className="me-3"
          breadcrumbItems={[{
            title: intl.formatMessage({ id: "invoice_header_title" }),
            to: INVOICES_PATH
          }]}
        />

        {displayStatus && (
          <div>
            <Label className="me-1"><FormattedMessage id="order_status" /> :</Label>
            <Badge color={InvoiceStateMap[status].color} pill>{intl.formatMessage({ id: InvoiceStateMap[status].label })}</Badge>
          </div>
        )}

        <div>
          <Label className="me-1 ms-3"><FormattedMessage id="invoice_name_order" /> :</Label>
          <b>{invoice.customerOrderName || "-"}</b>
        </div>

        <div className="d-flex ms-auto">
          <ButtonGroup title="Actions" className="me-2">
            <>
              {invoice?.status !== InvoiceState.BILLED &&
                <>
                <DropdownItem color={ColorType.PRIMARY} className="epow-button d-flex align-items-center" onClick={onClickAdd}>
                  <Icon name="Plus" className="ms-0 me-2" />
                  <div>{intl.formatMessage({ id: "invoice_billing_item_title_add" })}</div>
                </DropdownItem>
                  <DropdownItem color={ColorType.PRIMARY} className="epow-button d-flex align-items-center" onClick={onClickContactEdit}>
                    <Icon name="User" className="ms-0 me-2" />
                    <div>{intl.formatMessage({ id: "invoice_billing_item_edit_contact" })}</div>
                  </DropdownItem>
                </>
              }
              <DropdownItem color={ColorType.SECONDARY} className="epow-button d-flex align-items-center" onClick={onClickDownloadPdf}>
                <Icon name="Download" className="ms-0 me-2" />
                <div>{intl.formatMessage({ id: "invoice_download" })}</div>
              </DropdownItem>
              <DropdownItem color={ColorType.SECONDARY} className="epow-button d-flex align-items-center" onClick={onClickDownloadVouchers}>
                <Icon name="Download" className="ms-0 me-2" />
                <div>{intl.formatMessage({ id: "billing_dropdown_voucher_download" })}</div>
              </DropdownItem>
              {invoice.status != InvoiceState.DRAFT &&
                <DropdownItem color={ColorType.SECONDARY} className="epow-button d-flex align-items-center" onClick={onClickDownloadReminder}>
                  <Icon name="Download" className="ms-0 me-2" />
                  <FormattedMessage id="billing_dropdown_payment_download" />
                </DropdownItem>
              }
              {!invoice?.linkedInvoiceId && !isCustomer && invoice.status != InvoiceState.DRAFT &&
                <DropdownItem color={ColorType.SECONDARY} className="epow-button d-flex align-items-center" onClick={() => setOpenModal(true)}>
                  <Icon name="File" className="ms-0 me-2" />
                  <FormattedMessage id="invoice_credit_note" />
                </DropdownItem>
              }
            </>
          </ButtonGroup>

          {invoice?.status === InvoiceState.DRAFT &&
            <>
              <Button className="me-2" color={ColorType.DANGER} onClick={onDelete}>
                <FormattedMessage id="invoice_delete" />
                <Icon name="Trash" className="ms-2" />
              </Button>
              <Button color={ColorType.PRIMARY} onClick={onClickValidate}>
                <FormattedMessage id="invoice_validate" />
                <Icon name="Download" className="ms-2" />
              </Button>
            </>
          }
        </div>
      </div>

      <div className="d-flex">
        <div className="me-2">
          <Label className="me-1"><FormattedMessage id="purchase_order_creation"/></Label>
          {dateUtils.formatDateDayJs(invoice?.date)}
        </div>

        <div className="ms-5">{statusDetails[invoice.status]}</div>

        {invoice?.linkedInvoiceId &&
          <div className="ms-5 me-2">
            <Link to={`${CREDIT_NOTES_PATH}/${invoice.linkedInvoiceId}`}><FormattedMessage
              id="go_to_credit_note"/></Link>
          </div>
        }
      </div>

      <ModalCreditNoteConfirm
        open={openModal}
        onOpen={setOpenModal}
        onClose={() => setOpenModal(false)}
        invoiceNumber={invoice.invoiceNumber}
        invoice={invoice} />

      <ModalRemindInvoiceCustomer
        onSubmit={(content) => handleSendMail(content)}
        open={openRemindInvoiceCustomerModal}
        onOpen={(open) => setOpenRemindInvoiceCustomerModal(open)}
        onClose={() => setOpenRemindInvoiceCustomerModal(false)}
        invoice={invoice}
      />

    </div>
  )
}

export default InvoiceHeader
