import React, {FunctionComponent} from "react";
import Card from "../../atoms/Card";
import Button from "../../atoms/Button";
import Icon from "../../icon/Icon";
import {IconSizeType} from "../../../types/bootstrap/BootstrapType";
import {FormattedMessage} from "react-intl";
import {NotificationType} from "../../../constants/NotificationConstants";

export interface Priority {
  id: string,
  title: string,
  url?: string,
  content: JSX.Element,
  type: string,
  buttonMessage?: string
}

interface DashboardPriorityProps {
  className?: string;

  priority?: Priority
  type?: NotificationType
  onRemovePriority?: (priority: Priority) => void

}

const DashboardPriorityItem: FunctionComponent<DashboardPriorityProps> = ({
  priority,
  onRemovePriority
}) => {
  const buttonMessage = priority.url ? "dashboard_resolve" : "dashboard_notification_close";
  return <>
    <Card key={priority.id}
          className={`d-flex flex-row justify-content-between color-border-${priority.type} mt-2`}>
      <div className="w-100">
        <span className={`color-${priority.type}`}><FormattedMessage id={priority.title}/></span>
        <div className="wk-ellipsis">{priority.content}</div>
      </div>
      <Button onClick={() => onRemovePriority(priority)} color="primary">
            <span className="fs-10 me-1">
              <FormattedMessage id={priority.buttonMessage ?? buttonMessage}/>
            </span>
        <Icon name="ChevronRight" size={IconSizeType.XXS}/>
      </Button>
    </Card>
  </>;
};


export default DashboardPriorityItem;
