/**
 * File listing every translated label in French
 */

const FrenchMessages = {
  server_check: "État de la communication avec le serveur : ",
  logo_alt: "Logo Hippo",
  footer_link_cgu: "Politiques de confidentatialité - CGU",
  close: "Fermer",

  // GUARDS
  guards_auth_error: "Vous devez être authentifié(e) pour accéder à cette route!",
  guards_profile_restriction_error: "Vous n'avez pas les permissions pour accéder à cette route!",
  guards_profile_restriction_error_resource: "Vous n'avez pas les permissions pour accéder à cette route pour l'instant! Votre devez valider votre profil!",
  guards_rules_error: "Vous devez accepter les règles d'utilisation afin de pouvoir accéder au site",

  //FORMS
  required: "Ce champ est requis",
  login_invalid_length: "L'email doit contenir entre 3 et 50 caractères",
  password_invalid: "Le mot de passe ne respecte pas les contraintes",
  editable_field: "Champ éditable",

  // DASHBOARD
  dashboard_welcome: "Bienvenue {firstname} | ",
  dashboard_note: "Note :",
  dashboard_no_note: "Aucune note",
  dashboard_note_added_by: "Ajouté le {date} - {createdBy} ",
  dashboard_resolve: "Consulter",
  dashboard_resolve_and_close: "Consulter et fermer",
  dashboard_notification_close: "Fermer",
  dashboard_priority_title: "A consulter",
  dashboard_next_tour_leader_orders: "Mes prochains tours",
  dashboard_next_activities: "Prochaines prestations",
  dashboard_no_next_activities: "Aucune prestation à venir",
  dashboard_next_services: "Prochains services",
  dashboard_services_today: "Services du jour",
  dashboard_no_next_services: "Pas de service à afficher pour cette date",
  dashboard_orders_today: "Commandes du jour",
  dashboard_next_no_activities: "Aucune prestation à venir",
  dashboard_calendar: "Calendrier",
  dashboard_taken_order: "Prise de commande",
  dashboard_forecast: "Prévisionnel",
  dashboard_order: "Commandes",
  dashboard_panel_staffing_colleague: "Vos collègues",
  dashboard_panel_activity_comment: "Commentaire",
  dashboard_panel_activity_rendez_vous_place: "Lieu de RDV",
  dashboard_panel_activity_departure_hour: "Heure de départ",
  dashboard_panel_activity_rendez_vous_date_hour: "Date et heure de rendez-vous",
  dashboard_panel_activity_rendez_vous_duration: "Durée",
  dashboard_panel_activity_pax: "PAX",
  dashboard_panel_activity_tour_leader: "Tour leader",
  dashboard_panel_activity_staffing: "Ressource(s)",
  dashboard_panel_activity_description: "Description",
  dashboard_panel_activity_documents: "Documents",
  dashboard_panel_activity_additional_infos: "Informations complémentaires",
  dashboard_panel_activity_download_infos: "Télécharger tous les fichiers",
  dashboard_panel_activity_ics: "Télécharger le fichier .ics",
  dashboard_for_your_information: "Autres prestations du jour pour cette commande",
  dashboard_error_message: "Impossible de récuperer les informations du dashboard",
  last_month: "Mois dernier",
  hide: "Masquer",
  dashboard_next_tasks: "Prochaines tâches",
  dashboard_tasks_orders: "Commandes à confirmer",
  dashboard_tasks_to_staff: "Prestations à staffer",
  dashboard_tasks_booking_todo: "Besoins de réservation",
  dashboard_tasks_booking_pending: "Réservations à confirmer",
  dashboard_tasks_staffed: "Ressources staffées à ce jour",
  dashboard_customer_select_customer: "Sélectionner client : ",
  dashboard_supplier_select_supplier: "Sélectionner fournisseur : ",
  dashboard_activities_new_counter: "Propositions de service",
  dashboard_activities_awaiting_counter: "Services en attente de confirmation",
  dashboard_activities_confirmed_counter: "Services confirmés",
  dashboard_new_activities_counter: "Nouvelles prestations Paris On the Way",
  dashboard_activities: "Activités",
  dashboard_tasks_resource_bills_to_check: "Factures ressources à vérifier",
  dashboard_tasks_resource_bills_to_pay: "Factures ressources à régler",
  dashboard_tasks_resource_missing_voucher: "Prestations sans voucher",

  // CONTACTS
  contact_title: "Contacts",
  contact_no_result: "Il n'y aucun contact.",
  email: "Email",
  phoneNumber: "Téléphone",
  address: "Adresse",
  has_no_account: "Pas de compte",
  has_account: "A accès à la plateforme",
  invite: "Inviter",
  contact_customer_field: "Client",
  contact_last_name_field: "Nom",
  contact_first_name_field: "Prénom",
  contact_job_field: "Rôle / Service",
  contact_phone_number_field: "Numéro de téléphone",
  contact_other_phone_number_field: "Autre numéro de téléphone",
  contact_email_field: "Mail",
  contact_has_access_platform_field: "Accès à la plateforme",
  contact_search_placeholder: "Rechercher un contact...",
  create_new_contact: "Créer un nouveau contact",
  create_new_user: "Créer un nouveau utilisateur",
  contact_table_name: "Nom",
  contact_table_job: "Rôle / Service",
  contact_table_phone: "Téléphone",
  contact_table_email: "Email",
  contact_table_platform_access: "Accès à la plateforme",
  main_contact_email: "Email",
  error_inviting_contact: "Une erreur est survenue lors de l'invitation du contact",
  contact_invited: "Le contact a bien été invité",

  // SUPPLIERS
  supplier_title: "Fournisseurs",
  create_new_supplier: "Créer un fournisseur",
  supplier_no_result: "Il n'y a aucun fournisseur",
  supplier_placeholder: "Rechercher un fournisseur",
  supplier_table_search_placeholder: "Nom du fournisseur",
  supplier_table_header_name: "Nom",
  supplier_table_header_contact: "Contact principal",
  supplier_table_header_contact_email: "Email du contact",
  supplier_table_header_services: "Nb services",
  supplier_table_header_bookings: "Réservations en cours",
  supplier_table_service_number: "{serviceCount} service(s)",
  supplier_table_booking_number: "{bookingCount} réservations(s)",
  dropdown_header_supplier: "Fournisseur",
  supplier_name_field: "Nom",
  supplier_name: "Nom du fournisseur",
  supplier_general_info: "Informations générales",
  supplier_main_contact: "Contact principal",
  contact_supplier_field: "Fournisseur",
  supplier_settings_info: "Paramètres",
  supplier_pre_booking_delay: "Délai de pré-réservation (en jours)",
  supplier_payment_delay: "Délai de règlement (en jours)",
  supplier_email: "Email société",
  supplier_phone: "N° téléphone société",
  supplier_comment: "Commentaire",
  supplier_apply_vat: "Appliquer la TVA",
  supplier_has_voucher: "Voucher",
  supplier_current_bookings_number: "Nombre de réservations en cours",
  supplier_banking_info: "Données bancaires",
  supplier_bank_account_id: "Identifiant/code interne",
  add_contact_to_supplier: "Ajouter un contact au fournisseur",
  supplier_catalog: "Catalogue fournisseur",

  // BANK ACCOUNTS
  bank_account_not_found: "Aucun compte en banque trouvé",
  add_bank_account: "Ajouter un compte bancaire",
  bank_account_name: "Nom du compte",
  bank_name: "Nom de la banque",
  bank_code: "Code banque",
  bank_counter_code: "Code guichet",
  bank_account_number: "N° de compte",
  bank_rib_key: "Clé RIB",
  bank_iban_code: "Code IBAN",
  bank_bic_code: "Code BIC",
  modify_bank_account: "Modifier un compte bancaire",
  verify_iban_code: "Vérifier le code IBAN",
  iban_validation_error: "Le code IBAN n'est pas valide",
  bic_validation_error: "Le code BIC n'est pas valide",

  // CUSTOMERS
  customer_title: "Clients",
  customer_no_result: "Il n'y a aucun client.",
  create_new_customer: "Créer un nouveau client",
  customer_list_search_label: "Rechercher un client",
  customer_list_search_placeholder: "Nom, code client",
  customer_table_header_name: "Nom",
  customer_table_header_contact: "Contact principal",
  customer_table_header_tours: "Tours",
  customer_table_header_orders: "Commandes",
  customer_table_tour_number: "{tourNumber} tour(s)",
  customer_table_order_number: "{orderNumber} commande(s)",
  customer_placeholder: "Rechercher un client",
  dropdown_header_customer: "Client",
  customer_name_field: "Nom du client",
  customer_code_field: "Code client",
  customer_intra_vat_field: "TVA Intracommunautaire",
  customer_general_info: "Informations générales",
  main_contact: "Contact principal",
  customer_comment: "Remarques",
  company_name: "Nom de l'entreprise",
  customer_code: "Code client",
  customer_catalog: "Catalogue client",
  add_customer: "Ajouter un client",
  update_customer: "Modifier un client",
  customer_field: "Client",
  customer_tour_not_found: "Aucun client pour ce tour",
  customer_payment_condition: "Conditions de paiement",
  payment_condition_AT_RECEPTION: "A la réception",
  payment_condition_THIRTY_DAYS_AFTER_RECEPTION: "30 jours après la réception",

  // BILLING
  billed_by_default: "Facturé par défaut à ",
  order_item_order_number: "Numéro de commande ou code client",
  dropdown_header_order_customer: "Clients donneurs d'ordres",
  dropdown_header_billing_customer: "Clients facturés",
  billing_from: "Date de début",
  billing_to: "Date de fin",
  billing_no_result: "Rien à facturer sur la période pour les critères sélectionnés",
  billing_done: "En facturation",
  menu_billing: "Facturation",
  billing_title: "Facturation des prestations",
  billing_table_header_name: "Prestation / Service",
  billing_table_header_action_voucher: "Actions Voucher",
  billing_table_header_order: "Commande",
  billing_table_header_billing_service: "Client facturé",
  billing_table_header_date: "Date de la prestation",
  billing_table_header_status: "Status",
  billing_table_filter_missing_voucher: "Uniquement les prestations sans voucher",
  billing_create_invoice: "Créer les préfactures",
  billing_download_invoice: "Télécharger la facture",
  billing_item_auto_complete_info: "Les informations complémentaires de la ligne de commande seront automatiquement complétées si les champs ci-dessous ne sont pas renseignés.",
  billing_item_name: "Nom du service facturé",
  billing_item_quantity: "Quantité",
  billing_item_unit_price: "Prix unitaire",
  billing_item_toast_success_deletion: "La ligne de facturation a bien été supprimée",
  billing_item_toast_error_deletion: "Une erreur est survenue lors de la suppression de la ligne de facturation",
  billing_item_toast_error_creation: "Une erreur est survenue lors de la création de la ligne de facturation",
  billing_field_contact: "Contact",
  billing_field_contact_placeholder: "Rechercher un contact",
  billing_field_voucher_missing: "Manquant",
  billing_field_voucher_added: "Ajouté",
  billing_field_voucher_validated: "Validé",
  billing_dropdown_voucher_download: "Télécharger les vouchers",
  billing_download_no_result:"Aucun document à télécharger",
  billing_download_error:"Une erreur est survenue lors du téléchargement des documents",
  billing_dropdown_payment_download: "Télécharger le PDF de relance",
  billing_include_reminder_pdf_in_mail: "Inclure le PDF de relance dans le mail",

  // TOUR LEADERS
  tour_leader_title: "Tour Leaders",
  tour_leader_no_result: "Il n'y a aucun tour leaders.",
  create_new_tour_leader: "Créer un nouveau tour leader",
  tour_leader_table_header_name: "Nom",
  tour_leader_table_header_email: "Email",
  tour_leader_table_header_phone: "N° téléphone",
  tour_leader_table_header_customers: "Nb clients",
  tour_leader_table_header_orders: "Nb commandes",
  tour_leader_table_customer_number: "{customerNumber} clients",
  tour_leader_table_order_number: "{orderNumber} commandes",
  tour_leader_first_name_field: "Prénom",
  tour_leader_last_name_field: "Nom",
  tour_leader_email_field: "Email",
  dropdown_header_tour_leader: "Tour Leader",
  tour_leader_general_info: "Informations générales",
  customers_represented_by_tour_leader: "Clients représentés",
  customer_represented_by_tour_leader_field: "Client(s) représenté(s)",
  customer_represented_by_tour_leader_no_result: "Aucun client représenté par ce tour leader",
  tour_leader_search_placeholder: "Prénom, nom",
  tour_leader_search_title: "Recherche un tour leader",

  tour_leader_order_email_title: "Envoi d'email",
  tour_leader_order_email_greetings: "Bonjour {fullName}",

  tour_leader_order_email_body: "Please find below the names(s) of your resources and timings for the following tour code :", // Ne pas traduire
  tour_leader_order_email_body2: "Could you please double check the below information and let me know if all is fine on your side.", // Ne pas traduire
  tour_leader_order_email_bye: "Cordialement",
  tour_leader_order_email_meeting: "Rendez-vous à {rendezVousHour}",
  tour_leader_order_email_departure: "Départ à {departureHour}",
  tour_leader_order_email_departure_place: "Lieu de rendez-vous : {rendezVousPlace}",
  tour_leader_order_email_comment: "Description : {description}",
  tour_leader_order_email_ending: "Find all the informations needed on your dedicated connection on {softouringLink} or send us an email at contact@parisontheway.fr.",  // Ne pas traduire
  tour_leader_order_email_order_pax: "PAX du tour : {pax}",
  tour_leader_order_email_order_item_pax: "PAX du service : {pax}",
  tour_leader_remind_toast_success: "Un email a bien été envoyé au tour leader",
  tour_leader_remind_toast_error: "Une erreur est survenue lors de l'envoi de l'email",
  resource_remind_all_toast_success: "Un email a bien été envoyé",
  resource_remind_all_toast_error: "Une erreur est survenue lors de l'envoi de l'email",

  // TOURS
  tour_title: "Tours",
  tour_no_result: "Il n'y a aucun tour.",
  dropdown_header_tour: "Tour",
  tour_name_search: "Rechercher un tour",
  tour_table_search_placeholder: "Nom, code tour",
  tour_placeholder: "Rechercher un tour",
  create_new_tour: "Créer un nouveau tour",
  tour_table_header_code: "Code tour",
  tour_table_header_name: "Nom",
  tour_table_header_customers: "Nb clients",
  tour_table_header_activities: "Nb prestations",
  tour_table_header_orders: "Nb commandes",
  tour_table_customer_number: "{customerCount} client(s)",
  tour_table_activity_number: "{activityCount} prestation(s)",
  tour_table_order_number: "{orderCount} commande(s)",
  tour_tour_name_field: "Nom du tour",
  tour_tour_code_field: "Code du tour",
  tour_name: "Nom",
  tour_code: "Code Tour",
  tour_number_pax: "PAX",
  tour_general_info: "Informations générales",
  create_new_tour_activity: "Ajouter une prestation au tour",
  tour_activity_day_after_arrival_field: "Nombre de jours après l'arrivée",
  tour_activity_hour_field: "Heure de la prestation",
  tour_activity_departure_hour_field: "Heure de départ",
  tour_activity_optional_field: "Optionnel",
  tour_activity_description_field: "Description",
  tour_activity_meeting_place_field: "Lieu de RDV",
  tour_activity_billing_client_activity_name: "Nom de facturation client",
  tour_activity_resource_number_field: "Nombre de ressources",
  tour_activity_field: "Prestation",
  tour_activity_no_result: "Aucune prestation pour ce tour",
  tour_customer_field: "Client facturé par défaut",
  edit_tour_activity: "Modifier une prestation du tour",

  // SERVICE
  supplier_reference_field: "Référence",
  supplier_description_field: "Description",
  dropdown_header_service: "Service",
  service_title: "Services",
  service_placeholder: "Rechercher un service",
  service_table_search_placeholder: "Nom",
  service_no_result: "Il n'y a aucun service",
  create_new_service: "Créer un service",
  service_table_header_name: "Nom",
  service_table_header_supplier: "Fournisseur par défaut",
  service_table_header_bookings: "Réservations en cours",
  service_table_booking_number: "{bookingCount} réservation(s)",
  service_general_info: "Informations générales",
  service_name: "Nom",
  service_reference: "Reference",
  service_description: "Description",
  suppliers_for_service: "Fournisseurs du service",
  service_default_supplier: "Fournisseur par défaut",
  service_change_default_supplier: "Changer",
  number_of_services: "Nombre de services",
  service_activity: "Prestation",
  customer_service_not_found: "Aucun client pour ce service",
  service_unit_type: "Unité",
  service_unit_sell_type: "Unité de vente",
  service_group_size: "Taille du groupe",
  service_unit_price_excluding_tax: "Prix d'achat HT (€)",
  service_sell_price_excluding_tax: "Prix de vente HT (€)",
  service_start_date_validity: "Début de validité",
  add_customer_service_info_catalog: "Ajouter les informations du catalogue client du service",
  add_supplier_service_catalog: "Ajouter les informations du catalogue fournisseur du service",
  service_number_pax: "Pax max",
  service_default_unit: "Unité par défaut",
  service_default_sell_unit: "Unité de vente par défaut",
  service_default_price: "Prix d'achat par défaut (€)",
  service_unit_price: "Prix d'achat",
  service_default_sell_price: "Prix de vente par défaut (€)",
  service_vat_rate: "TVA Applicable (%)",

  // RESOURCE
  create_new_resource: "Créer une ressource",
  resource_title: "Ressources",
  resource_no_result: "Il n'y a aucune ressource de ce type",
  resource_table_header_name: "Nom",
  resource_table_header_email: "Email",
  resource_table_header_phone: "N° téléphone",
  resource_table_header_area: "Zone géographique",
  resource_table_header_availability: "Disponibilité",
  resource_table_header_activity_number: "Nb prestations staffées",
  resource_table_activity_number: "{activityCount} prestation(s)",
  resource_gender_field: "Genre",
  resource_last_name_field: "Nom",
  resource_first_name_field: "Prénom",
  resource_birth_name_field: "Nom de naissance",
  resource_nationality_field: "Nationalité",
  resource_origin_field: "Origine",
  resource_email_field: "Email",
  resource_phone_number_field: "Téléphone",
  resource_other_phone_number_field: "Autre téléphone",
  resource_search_placeholder: "Prénom, nom, email",
  resource_create: "Créer une nouvelle ressource",
  dropdown_header_resource: "Ressource",
  resource_name_filter: "Rechercher une ressource",
  resource_filter_criteria: "Critères de filtre :",
  resource_filter_staffing_to_type: "Envoyé aux :",
  resource_filter_staffing_to_type_all: "Tous",
  resource_filter_on_activity: "Prestation",
  resource_filter_on_kids: "Kids",
  resource_filter_on_public_type: "Type de public",
  resource_general_info: "Informations générales",
  resource_comment: "Remarques",
  resource_professional_situation: "Situation professionnelle",
  resource_status_field: "Statut",
  resource_level_field: "Niveau",
  resource_date_first_mission_field: "Date de première mission",
  resource_seniority_field: "Ancienneté",
  resource_seniority_value: "{years} ans et {months} mois",
  resource_availability_field: "Critère de disponibilité",
  resource_structure_field: "Structure attachée",
  resource_structure_field_popover: "Si la structure attachée est incorrecte, contacter votre administrateur",
  resource_card_field: "Carte professionnelle",
  resource_job_info: "Métier",
  spoken_languages: "Langues parlées",
  resource_legal_info: "Informations légales",
  resource_public_liability_card: "Responsabilité civile",
  resource_rcp_cover_start_date: "Date de début de couverture RCP",
  resource_rcp_validity_date: "Date de validité RCP",
  resource_urssaf_card: "Situation URSSAF",
  resource_urssaf_situation_date: "Date de situation URSSAF",
  resource_public_liabiliy_card_field: "Responsabilité civile",
  resource_turnover_popover: "Indiquez le montant de votre chiffre d'affaires de l'année précédente",
  resource_public_type: "Type de public",
  resource_display_options_field: "Régle d'affichage des ressources",
  resource_display_option_none: "Ne rien afficher",
  resource_display_option_name: "Prénom",
  resource_display_option_name_phone: "Prénom et téléphone",
  resource_toast_picture_error: "Problème lors de la récuperation de la photo",
  resource_toast_resource_error: "Problème lors de la récuperation de la resource pour afficher la photo de profil",
  resource_notify_remove_voucher_toast_success: "Le voucher a bien été retiré et la ressource a été notifiée",
  resource_notify_remove_voucher_toast_error: "Une erreur est survenue",
  resource_remind_toast_success: "Un email a bien été envoyé à la ressource",
  resource_remind_toast_error: "Une erreur est survenue lors de l'envoi de l'email",
  resource_rcp_validity_date_before_start_date: "La date de validité RCP doit être postérieure à la date de début de couverture",
  resource_validate_voucher_success: "Le voucher a bien été validé",
  resource_validate_voucher_error: "Une erreur est survenue lors de la validation du voucher",


  resource_activities_handling: "Sélection des prestations maîtrisées",
  resource_activities_search: "Recherchez des prestations...",
  resource_activities_add: "Ajouter",
  resource_activities_to_add: "Prestations à sélectionner",
  resource_activities_to_add_sublabel: "Choisir les prestations maîtrisées dans la liste ci-dessous",
  resource_activities_known_mobile_label: "Voir les {count} prestations maîtrisées",
  resource_activities_known: "Prestations maitrisées",
  resource_activities_known_sublabel: "Liste des prestations déclarées comme maîtrisées",
  resource_activities_add_success: "La(les) prestation(s) a(ont) bien été ajoutée(s)",
  resource_activities_add_error: "Une erreur est survenue lors de l'ajout des prestations à la ressource",
  resource_activities_get_error: "Une erreur est survenue lors de la récupération des prestations associées à la ressource",
  resource_activities_delete_error: "Une erreur est survenue lors de la suppression de la prestation associée à la ressource",
  resource_activities_table_column_name: "Nom de la prestation associée",
  resource_activities_table_delete_selection: "Supprimer la sélection",
  resource_profile_picture: "Photo de profil",
  resource_validate_profile_help_label: "Remplissez les 5 onglets \"{tab1}\", \"{tab2}\", \"{tab3}\", \"{tab4}\" et \"{tab5}\" et validez votre fiche.\nAucune prestation ne pourra vous être attribuée sinon",
  resource_remind_voucher_upload_title: "Relancer",
  resource_remind_resource_voucher_upload: "Relancer Guides",
  resource_remind_all_resource_voucher_upload_btn: "Relancer tous les guides",
  resource_remind_all_tour_leaders_voucher_upload_btn: "Relancer tous les Tour leaders",
  resource_remind_tour_leaders_voucher_upload: "Relancer Tour Leaders",
  resource_remind_everyone_voucher_upload_btn: "Relancer tout le monde",
  resource_origin_fr: "Ressource en France (FR)",
  resource_origin_eu: "Ressource Européenne (EU)",
  resource_origin_not_eu: "Ressource étrangère (Hors UE)",
  resource_origin_placeholder: "Origine",
  resource_remind_voucher_mail_greetings: "Madame, Monsieur,",
  resource_remind_voucher_mail_body_1: "Veuillez téléverser un coupon concernant l'activité {name} se déroulant le {date}. Dans l’attente de votre retour.",
  resource_remind_voucher_mail_body_2: ", du code tour {tourCode} daté du {date} est manquant.",
  resource_remind_voucher_mail_bye_1: "Cordialement,",
  resource_deny_voucher_mail_greetings: "Bonjour,",
  resource_deny_voucher_mail_body_1: "Le voucher concernant la prestation ",
  resource_deny_voucher_mail_body_2: ", du code tour {tourCode} daté du {date} est erroné.",
  resource_deny_voucher_mail_body_3: "Veuillez déposer un voucher correct.",
  resource_deny_voucher_mail_body_4: "Vous remerciant pour votre retour, la réception de ce voucher étant obligatoire à réception de la préstation et obligatoire pour l'établissement de la facture au client.",
  resource_deny_voucher_mail_bye_1: "Cordialement,",


  // STRUCTURES
  create_new_structure: "Créer une nouvelle structure",

  // USERS
  filter_validated: "Actif",
  filter_profile: "Profil",
  user_title: "Utilisateurs",
  user_activate_modal_title: "Activer un utilisateur",
  user_deactivate_modal_title: "Désactiver un utilisateur",
  user_activate_modal_message: "Êtes-vous sûr de vouloir activer l'utilisateur {fullName} ?",
  user_deactivate_modal_message: "Êtes-vous sûr de vouloir désactiver l'utilisateur {fullName} ?",
  user_no_result: "Il n'y a aucun utilisateur.",
  users_table_header_name: "Nom",
  users_table_header_email: "Email",
  users_table_header_profiles: "Profil(s)",
  users_table_header_active: "Actif",
  users_table_header_created_on: "Créé le",
  users_table_header_last_connection: "Dernière connexion",
  edit_user: "Modifier l'utilisateur",
  user_first_name: "Prénom",
  user_last_name: "Nom de famille",
  user_email: "Email",
  user_profiles: "Profil(s)",
  user_phoneNumber: "Numéro de téléphone",
  user_other_phoneNumber: "Autre numéro de téléphone",
  user_job: "Poste",
  dropdown_header_user: "Utilisateur",

  // ORDERS
  dropdown_header_order: "Commande",
  order_title: "Commande",
  order_number: "N° de commande",
  order_default_pax:"Pax par défaut",
  order_customer: "Client",
  order_customer_ref: "Réf client",
  order_tour: "Tour",
  order_referenceClient: "Référence client",
  order_dayZero: "Date de début du tour",
  order_date: "Date de commande",
  create_orders: "Créer les commandes",
  create_order: "Créer une commande",
  order_csv_title: "Import d'une liste de commande",
  order_csv_button: "Import CSV",
  order_travel_book_button: "Exporter le carnet de voyage",
  order_csv_success: "Les commandes ont bien été importées",
  order_csv_import_error: "Une erreur est survenue lors de l'import du csv",
  order_csv_error: "Corrigez les erreurs restantes afin d'importer les commandes",
  orders_title: "Commandes",
  orders_form_title: "Modification de commandes",
  order_import_contains_errors: "La liste des commandes contient des erreurs",
  order_no_result: "Il n'y a aucune commande.",
  order_status: "Statut",
  order_comment: "Commentaire",
  order_no_order_item: "Votre commande ne contient aucune prestation",
  order_add_order_item: "Ajouter une ligne",
  order_item_email_send: "Envoyer Commande",
  order_item_email_sent: "Commande envoyée",
  order_creation_date: "Créé le",
  order_tour_leader: "Tour leader",
  order_client_does_not_exist: "Le client est inconnu ou inactif",
  already_exists_customer_ref: "La référence client est déjà utilisée",
  order_tour_does_not_exist: "Le tour est inconnu ou inactif",
  order_tour_does_not_exist_with_client: "Le tour n'est pas disponible pour ce client",
  order_day_zero_before_today: "La date J0 du tour est antérieure à aujourd'hui",
  order_day_zero_before_order_date: "La date J0 du tour est antérieure à la date de commande",
  order_activities: "Prestations",
  order_price: "Total HT",
  order_filter_done: "Terminées",
  order_filter_coming: "A venir",
  order_filter_all_requests: "En cours",
  order_filter_open: "Ouvertes",
  order_filter_to_handle: "A traiter",
  order_filter_to_handled: "Traitée",
  order_filter_to_completed: "Terminée",
  order_filter_code_title: "Rechercher une commande",
  order_filter_code_placeholder: "Référence client, N° commande",
  order_filter_period_start: "Date de début",
  order_filter_period_end: "Date de fin",
  order_filter_customer: "Client",
  order_state_draft: "Créée",
  order_state_pending: "A traiter",
  order_state_handled: "Traitée",
  order_state_completed: "Terminée",
  order_state_cancelled: "Annulée",
  order_status_default: "Inconnu",
  order_status_cancelled: "Annulées",
  order_total_number: "{total} commandes",
  order_booking_to_do: "Effectuer les réservations",
  order_csv_template: "Télécharger le modèle excel",
  order_booking_create: "Créer les réservations",
  order_send_email:"Envoyer mail",

  // ORDER ITEMS
  create_new_order_item: "Ajouter une ligne",
  order_item_unit_type_pax: "PAX",
  order_item_unit_type_fixed_rate: "Forfait",
  order_item_type_activity: "Prestation",
  order_item_type_service: "Service",
  order_item_type: "Type",
  order_item_activity: "Prestation",
  order_item_service: "Service",
  order_item_name: "Nom",
  order_item_reference: "Reference",
  order_item_optional: "Optionnelle",
  order_item_is_staffing_management: "Gestion du staffing",
  order_item_is_booking_management: "Gestion réservation",
  order_item_activity_ops: "Prestation opérationnelle",
  order_item_description: "Description",
  order_item_place: "Lieu de rendez-vous",
  order_item_date: "Date de rendez-vous",
  order_item_cancel_date: "Date d'annulation",
  order_item_hour: "Heure de rendez-vous",
  order_item_departure_hour: "Heure de départ",
  order_item_pax: "PAX",
  order_item_pax_confirmed: "PAX confirmé",
  order_item_update_lines: "Mettre à jour les lignes",
  order_item_duration: "Durée",
  order_item_quantity: "Qté vendue",
  order_item_number_of_resources: "Nombre de : {label}",
  order_item_unit_type: "Unité",
  order_item_pax_price: "Prix unitaire HT (€)",
  order_item_sell_price: "Total HT (€)",
  order_item_sell_price_tva: "TVA à 20%",
  order_item_sell_price_read: "Prix de vente HT",
  order_item_sell_price_tva_read: "TVA",
  order_item_sell_price_total: "Prix de vente TTC (€)",
  order_item_billing_service: "Service de facturation",
  order_item_disbursements: "Debours",
  order_item_resources_to_staff: "Ressources à staffer",
  order_item_resources_staffed: "Nombre de ressource(s) staffée(s)",
  order_item_documents: "Document(s)",
  order_item_bookings: "Réservation(s)",
  order_item_state_draft: "Créée",
  order_item_state_pending: "A compléter",
  order_item_state_validated: "Complète",
  order_item_fetch_error: "Impossible de récupérer la ligne de commande",
  order_item_state_cancelled: "Annulée",
  order_item_filter_code_placeholder: "Rechercher une ligne commande",
  order_item_add_file: "Ajouter un autre document",
  order_item_add_voucher: "Ajouter un voucher",
  order_item_add_ticket: "Ajouter un billet",
  order_item_no_booking: "Aucune réservation",
  order_item_no_documents: "Aucun document",
  order_item_needs_tickets: "Attente billets client",
  order_item_update_success: "Ligne de commande modifiée avec succés",
  order_item_create_success: "Ligne de commande ajoutée avec succès",
  order_item_overbooked_resource: "Le nombre de guides staffés est trop important sur la prestation.",
  ACTIVITY: "Prestation",
  SERVICE: "Service",
  order_update_tour_leader: "Mise à jour du tour leader",
  order_update_tour_leader_success: "Le tour leader a bien été mis à jour",
  order_update_tour_leader_error: "Impossible de mettre à jour le tour leader",
  order_tour_leader_mail_pj: "Pièce(s) jointe(s) (5 max)",
  order_tour_leader_mail_cc: "Adresse mail à mettre en Cc",
  order_form_search: "Rechercher",
  order_form_confirm: "Valider les modifications",
  order_form_applied: "Modifications prise en compte!",
  order_filter_order_number: "Numéro de commande",
  order_mass_update_success: "Commandes mises à jour",

  // ACTIVITY
  activity_title: "Prestations",
  dropdown_header_activity: "Prestation",
  activity_placeholder: "Rechercher une prestation",
  activity_table_search_title: "Rechercher une prestation",
  activity_table_search_placeholder: "Rechercher une prestation",
  activity_table_filter_new: "Uniquement les prestations récentes",
  activity_no_result: "Aucune activité",
  activity_table_header_name: "Nom",
  activity_table_header_reference: "Référence",
  activity_table_header_activation_date: "Date d'activation",
  activity_table_header_duration: "Durée",
  activity_table_header_maxpax: "PAX max",
  activity_table_header_tours: "Proposé dans",
  activity_table_tour_number: "{tourCount} tour(s)",
  activity_table_new: "New",
  add_activity_to_tour: "Ajouter une prestation à la saison",
  add_config_activity_for_customer: "Configurer la prestation pour le client",
  day: "Jour",
  hour: "Heure de RdV",
  departureHour: "Heure de départ",
  add_activity_in_tour_for_customer: "Client {customer} - Prestation {activity}",
  setting_by_default: "Configuration par défaut :",
  activity_tour_not_found: "Aucune prestation pour cette saison",
  activity_tour_expand_all: "Tout déplier",
  create_new_activity: "Créer une nouvelle prestation",
  activity_name_field: "Nom",
  activity_reference_field: "Référence",
  activity_billing_name: "Nom de facturation",
  activity_duration: "Durée",
  activity_description: "Description",
  activity_number_pax_max: "PAX maximum",
  activity_zone: "Zone d'activité",
  activity_zone_placeholder: "Rechercher une zone géographique",
  activity_is_staffing_management: "Gestion du staffing",
  activity_is_for_kids: "Pour enfants",
  activity_price: "Prix de vente (€)",
  activity_informations_title: "Informations de vente",
  activity_resource_price: "Tarif ressource (€)",
  add_services_to_activity: "Ajouter des services à la prestation",
  services_in_activity: "Services",
  activity_resource_price_title: "Tarif ressource",
  activity_start_date_validity: "Début de validité",
  activity_tariff: "Prix de vente (€)",
  activity_senior_employee: "Salarié sénior (€)",
  activity_junior_employee: "Salarié junior (€)",
  activity_senior_independent: "Indépendant sénior (€)",
  activity_junior_independent: "Indépendant junior (€)",
  activity_senior_employee_rate: "Tarif salarié sénior",
  activity_junior_employee_rate: "Tarif salarié junior",
  activity_senior_independent_rate: "Tarif indépendant sénior",
  activity_junior_independent_rate: "Tarif indépendant junior",
  activity_need_rate: "Au moins un tarif doit être renseigné",
  activity_customer_not_found: "Aucun client pour cette prestation",
  add_selling_price: "Ajouter un prix de vente",
  add_resource_rate: "Ajouter un tarif ressource",
  activity_list: "Liste des prestations",
  activity_hide_staffing: "Cacher les ressources",
  activity_show_staffing: "Afficher les ressources",
  activity_customer_pax_max_customer: "PAX Max. Client",
  activity_customer_billing_name: "Nom de facturation",
  activity_linked_activities_title: "Prestations associées à la prestation",
  activity_linked_activities_suppliers_title: "Prestations à associer",
  activity_linked_activities_elements_title: "Prestations associées",
  activity_linked_activities_elements_mobile_label: "Voir les {count} prestations associées",
  activity_more_info: "Plus d'infos",
  activity_customer_delete_title: "Suppression d'un catalogue client",
  activity_customer_delete_body: "Êtes-vous certain(e) de vouloir supprimer l'entièreté du catalogue du client {client} pour cette prestation ?",
  activity_customer_delete_success: "Le catalogue a bien été supprimé",
  activity_customer_delete_failure: "Une erreur est survenue lors de la suppression du catalogue client",
  activity_ticket_tour_leader: "Réserver le ticket pour le tour leader",
  activity_send_new_activity_mail: "Envoyer un mail pour les nouvelles prestations",

  // PROFILE
  profile: "Profil",
  profile_all: "Tous",


  // MENU
  menu_dashboard: "Tableau de bord",
  menu_sales: "Ventes",
  menu_invoice: "Facturation",
  menu_invoice_list: "Liste des factures",
  menu_invoice_payment: "Suivi des règlements",
  menu_purchase: "Achats",
  menu_staffing: "Staffing",
  menu_staffing_request: "Mes demandes",
  menu_catalog: "Référentiel",
  menu_documentary_base: "Base documentaire",
  menu_customer: "Client",
  menu_tour: "Tour",
  menu_tour_leader: "Tour Leader",
  menu_quotes: "Devis",
  menu_order: "Commande",
  menu_order_tour: "Mes tours",
  menu_admin: "Administration",
  menu_activity: "Prestation",
  menu_service: "Service",
  menu_resource: "Ressources",
  menu_supplier: "Fournisseur",
  menu_staffing_list: "Gestion staffing",
  menu_billing_overview: "Factures ressources",
  menu_purchase_order: "Commandes d'achat",
  menu_booking: "Suivi des réservations",
  menu_self_staffing: "Mes prestations",
  menu_self_order: "Mes commandes",
  menu_self_bills: "Mes Factures",
  menu_ref: "Gestion référentiels",
  menu_params: "Paramétrage",
  menu_exports: "Exports",

  // ERRORS

  server_error: "Une erreur serveur est survenue",
  no_row: "Aucun résultats",
  mandatory_field: "Champ obligatoire",
  Forbidden: "Connexion impossible: le compte utilisateur est désactivé",
  document_delete_Forbidden: "Vous n'avez pas les droits pour supprimer ce document",
  Unauthorized: "Aucun compte utilisateur ne correspond à l'email et au mot de passe fourni",
  login_error: "Une erreur est survenue lors de la connexion",
  upload_bad_file_type: "Le fichier n'a pas la bonne extension",
  profile_not_selected: "Aucun profile sélectioné",
  field_email_invalid: "Cet email est invalide",
  field_number_positive_or_zero: "La valeur doit être supérieure ou égale à 0",
  field_number_positive: "La valeur doit être supérieure à 0",
  error_toast_create_customer: "Erreur lors de la création d'un client",
  error_toast_create_supplier: "Erreur lors de la création d'un fournisseur",
  error_toast_get_users: "Erreur lors de la récupération de la liste des utilisateurs",
  error_toast_get_user: "Erreur lors de la récupération de l'utilisateur",
  error_toast_get_contacts: "Erreur lors de la récupération de la liste des contacts",
  error_toast_get_activity_resource_requirements: "Erreur lors de la récupération des ressources requises pour la prestation",
  error_toast_get_customers: "Erreur lors de la récupération de la liste des clients",
  error_toast_get_customer: "Erreur lors de la récupération du client",
  error_toast_get_resources: "Erreur lors de la récupération de la liste des ressources",
  error_toast_get_tours: "Erreur lors de la récupération de la liste des tours",
  error_toast_get_one_customer: "Erreur lors de la récupération d'un client",
  max_length_error_field: "Le nombre de caractères maximum est de 20",
  invalid_iban_field: "Format d'IBAN invalide",
  invalid_date_field: "Date invalide",
  error_toast_create_contact: "Erreur lors de la création d'un contact",
  field_phone_number_invalid: "Ce numéro de téléphone est invalide",
  error_toast_get_main_contact: "Erreur lors de la récupération du contact principal",
  error_toast_update_customer: "Erreur lors de la modification d'un client",
  error_toast_update_supplier: "Erreur lors de la modification d'un fournisseur",
  error_toast_update_contact: "Erreur lors de la modification du contact",
  error_toast_delete_contact: "Erreur lors de la suppression du contact",
  error_toast_create_tour: "Erreur lors de la création d'un tour",
  error_toast_update_tour: "Erreur lors de la modification d'un tour",
  error_toast_get_activities: "Erreur lors de la récupération de la liste des activités",
  error_toast_create_tour_activity: "Erreur lors de l'ajout d'une prestation au tour",
  error_toast_get_tour_activities: "Erreur lors de la récupération des prestations d'un tour",
  error_toast_create_tour_customer: "Erreur lors de l'ajout d'un client au tour",
  error_toast_get_customers_by_tour: "Erreur lors de la récupération des clients par tour",
  error_toast_get_one_activity: "Erreur lors de la récupération d'une prestation",
  error_toast_create_tour_activity_customer: "Erreur lors de la création des catalogues par saison pour un client",
  error_toast_get_tour_customer_activities: "Erreur lors de la récupération des prestations spécifiques d'un tour pour un client",
  error_toast_delete_tour_activity: "Erreur lors de la suppression de la prestation d'un tour",
  error_toast_update_tour_activity: "Erreur lors de la modification de la prestation d'un tour",
  error_toast_update_tour_activity_customer: "Erreur lors de la modification d'une prestation spécifique dans un tour pour un client",
  error_toast_delete_tour_activity_customer: "Erreur lors de la suppression de la prestation d'un tour pour un client",
  error_toast_create_tour_leader: "Erreur lors de la création d'un tour leader",
  error_toast_create_activity: "Erreur lors de la création de la prestation",
  error_toast_get_one_tour: "Erreur lors de la récupération d'un tour",
  error_toast_get_one_service: "Erreur lors de la récupération d'un service",
  error_toast_get_one_resource: "Erreur lors de la récupération d'une ressource",
  error_toast_get_one_supplier: "Erreur lors de la récupération d'un fournisseur",
  error_update_supplier_settings: "Erreur de la modification des paramètres du fournisseur",
  error_toast_get_one_reference: "Erreur lors de la récupération de la référence",
  error_get_bank_accounts: "Erreur lors de la récupération des comptes en banque",
  error_get_black_date_accounts: "Erreur lors de la récupération des black dates",
  error_create_bank_account: "Erreur lors de la création d'un compte bancaires",
  error_delete_bank_account: "Erreur lors de la suppression du compte bancaire",
  error_update_bank_account: "Erreur lors de la modification du compte bancaire",
  error_toast_update_tour_leader: "Erreur lors de la modification d'un tour leader",
  error_toast_update_activity: "Erreur lors de la modification de la prestation",
  error_toast_get_services: "Erreur lors de la récupération des services",
  error_toast_staffing_request: "Erreur lors de l'envoi de la demande de staffing",
  error_create_resource: "Erreur lors de la création de la ressource",
  error_update_resource: "Erreur lors de la modification de la ressource",
  error_get_structures: "Erreur lors de la récupération des structures",
  error_create_structure: "Error lors de la création d'une structure",
  error_toast_send_mail_reset_password: "Erreur lors de l'envoi du mail pour réinitialiser le mot de passe",
  error_toast_reset_password: "Erreur lors de la réinitialisation de votre mot de passe",
  error_toast_get_activity_area: "Erreur lors de la récupération des zones de prestation",
  error_toast_create_activity_customer: "Erreur lors de l'ajout du client à la prestation",
  error_toast_create_activity_selling_price: "Erreur lors de l'ajout du prix de vente au client de la prestation",
  error_toast_create_activity_resource: "Erreur lors de l'ajout du tarif de ressource au client de la prestation",
  error_toast_update_activity_selling_price: "Erreur lors de la modification du prix de vente",
  error_toast_update_activity_resource: "Erreur lors de la modification du tarif ressource",
  error_toast_delete_activity_selling_price: "Erreur lors de la suppression du prix de vente",
  error_toast_delete_activity_resource_rate: "Erreur lors de la suppression du tarif ressource",
  error_toast_delete_customer_service_info_catalog: "Erreur lors de la suppression des informations du catalogue client",
  error_toast_update_customer_service_info_catalog: "Erreur lors de la modification des informations du catalogue client",
  error_toast_create_customer_service_info_catalog: "Erreur lors de la création des informations du catalogue client",
  error_toast_create_customer_service: "Erreur lors de l'ajout du client au service",
  error_get_languages: "Erreur lors de la récupération des langues",
  error_toast_staffing_validation: "Erreur lors de l'acceptation de la demande de staffing",
  error_toast_staffing_update: "Erreur lors de la mise à jour du staffing",
  error_toast_get_one_purchase: "Erreur lors de la récupération d'une commande d'achat",
  error_toast_delete_booking: "Erreur lors de la suppression de la réservation",
  error_toast_get_suppliers: "Erreur lors de la récupération de la liste des fournisseurs",
  error_toast_create_purchase_order: "Erreur lors de la création d'une commande d'achat",
  error_toast_create_booking: "Erreur lors de la création d'une réservation",
  error_toast_update_booking: "Erreur lors de la modification d'une réservation",
  error_toast_update_purchase: "Erreur lors de la modification d'une commande d'achat",
  error_retrieving_file: "Erreur lors de la récupération du fichier",
  error_toast_purchases_created: "Erreur lors de la création des commandes d'achat",
  error_toast_activate_user: "Erreur lors de l'activation de l'utilisateur",
  error_toast_deactivate_user: "Erreur lors de la désactivation de l'utilisateur",
  error_toast_update_user: "Erreur lors de la modification de l'utilisateur",
  error_toast_create_user: "Erreur lors de la création de l'utilisateur",
  error_toast_get_rules: "Erreur lors de la récupération de la liste des règles d'utilisation",
  error_toast_update_rule:" Erreur lors de la modification de la règle d'utilisation",
  error_toast_create_rule: "Erreur lors de la création d'une règle d'utilisation",
  error_toast_activate_rule: "Erreur lors de l'activation de la règle",
  error_toast_deactivate_rule: "Erreur lors de la désactivation de la règle",
  error_toast_upload_file: "Erreur lors de l'upload du fichier",
  error_toast_service_search: "Erreur lors de la récupération des services",
  error_toast_order_get: "Erreur lors de la récupération de la commande",
  error_toast_order_update: "Erreur lors de la mise à jour de la commande",
  error_toast_order_item_search: "Erreur lors de la récupération des lignes de commande",
  error_toast_order_item_update: "Erreur lors de la mise à jour des lignes de commande",
  error_toast_fetch_my_documents: "Erreur lors de la récupération des documents",
  error_toast_admin_config: "Erreur lors de la récupération des paramètres",
  error_toast_save_admin_config: "Erreur lors de la sauvegarde des paramètres",
  error_toast_delete_document: "Erreur lors de la suppression du document",
  error_toast_validate_order: "Erreur lors de la validation de la commande",
  error_toast_validate_orders: "Erreur lors de la validation des commandes",
  error_toast_edit_home_comment: "Erreur lors de la modification de la note",
  error_toast_create_home_comment: "Erreur lors de la création de la note",
  error_toast_get_home_comment: "Erreur lors de la récupération de la note",
  error_toast_update_booking_document: "Erreur lors de la mise à jour de la réservation",
  error_toast_cancel_booking_document: "Erreur lors de l'annulation de la réservation",
  error_toast_keep_booking_document: "Erreur lors de la réattribution d'une réservation",
  error_toast_add_order_item_file: "Erreur lors de l'ajout du document",
  error_toast_delete_staffing: "Erreur lors de la suppression du staffing",
  error_toast_send_order_mail: "Erreur lors de l'envoi de l'email",
  error_toast_sorting: "Erreur lors du tri",
  error_toast_get_suppliers_service: "Erreur lors de la récupération des fournisseurs du service",
  error_toast_delete_supplier_service_catalog: "Erreur lors de la suppression des informations du catalogue fournisseur",
  error_toast_update_supplier_service_catalog: "Erreur lors de la modification des informations du catalogue fournisseur",
  error_toast_create_supplier_service_catalog: "Erreur lors de la création des informations du catalogue fournisseur",
  error_profile_picture: "Erreur lors de l'envoi de la photo de profil",
  error_toast_delete_activity_service: "Erreur lors de la suppression du service",
  error_toast_calendar: "Erreur lors du chargement du calendrier",
  api_error_toast_duplicate_resource: "Erreur lors de la création de la Ressource : L’adresse mail existe déjà.",
  api_error_toast_duplicate_tour_leader: "Erreur lors de la création du Tour Leader : L’adresse mail existe déjà.",
  api_error_toast_duplicate_admin: "Erreur lors de la création de l'Admin : L’adresse mail existe déjà.",
  api_error_toast_duplicate_intern: "Erreur lors de la création de l'Interne : L’adresse mail existe déjà.",

  // SUCCESS
  success_toast_update_customer: "Modification du client réussie",
  success_toast_create_tour_activity: "La prestation a été rajoutée au tour avec succès. Attention celle-ci est également rajoutée à tous les clients liés à ce tour.",
  success_toast_create_tour_customer: "Le client a été rajouté au tour avec succès.",
  success_create_contact: "Vous avez créé un nouveau contact",
  success_create_customer: "Vous avez créé un nouveau client",
  success_create_supplier: "Vous avez créé un nouveau fournisseur",
  success_update_customer: "Vous avez modifié le client avec succès",
  success_update_supplier: "Vous avez modifié le fournisseur avec succès",
  success_toast_update_contact: "Vous avez modifié le contact avec succès",
  success_toast_delete_contact: "Vous avez supprimé le contact avec succès",
  success_toast_create_tour_activity_customer: "Les catalogues par saison pour le client ont bien été créés",
  success_toast_delete_tour_activity: "Vous avez supprimé la prestation d'un tour avec succès",
  success_toast_update_tour_activity: "Vous avez modifié la prestation d'un tour avec succès",
  success_toast_update_tour_activity_customer: "Vous avez modifié une prestation spécifique dans un tour pour un client",
  success_toast_delete_tour_activity_customer: "Vous avez supprimé la prestation d'un tour pour un client avec succès",
  success_toast_create_tour_leader: "Vous avez créé un nouveau tour leader",
  success_toast_create_activity: "Vous avez créé une nouvelle prestation",
  success_toast_update_tour_leader: "Vous avez modifié le tour leader avec succès",
  success_toast_update_self_tour_leader: "Vous avez modifié votre profil avec succès",
  success_toast_update_activity: "Vous avez modifié la prestation avec succès",
  success_update_supplier_settings: "Vous avez modifié les paramètres du fournisseur avec succès",
  success_create_resource: "Vous avez créé une ressource avec succès",
  success_update_resource: "Vous avez modifié la ressource avec succès",
  success_create_structure: "Vous avez créé une nouvelle structure",
  success_create_bank_account: "Vous avez créé un compte bancaire",
  success_delete_bank_account: "Vous avez supprimé le compte bancaire",
  success_update_bank_account: "Vous avez modifié le compte bancaire avec succès",
  sucess_toast_staffing_request: "Demande de staffing bien envoyée",
  success_toast_send_mail_reset_password: "Vous avez envoyé un mail pour réinitialiser le mot de passe",
  success_toast_reset_password: "Vous avez réinitialisé votre mot de passe avec succès",
  success_toast_update_activity_customer: "Le client a été mis à jour à la prestation avec succès",
  success_toast_create_activity_customer: "Le client a été rajouté à la prestation avec succès",
  success_toast_create_activity_selling_price: "Le prix de vente a été rajouté au client pour cette prestation",
  success_toast_create_activity_resource: "Le tarif de la ressource a été rajouté au client pour cette prestation",
  success_toast_update_activity_selling_price: "Vous avez modifié le prix de vente avec succès",
  success_toast_update_activity_resource: "Vous avez modifié le tarif de ressource avec succès",
  success_toast_delete_activity_selling_price: "Vous avez supprimé le prix de vente avec succès",
  success_toast_delete_activity_resource_rate: "Vous avez supprimé le tarif ressource avec succès",
  success_toast_delete_customer_service_info_catalog: "Vous avez supprimé les informations du catalogue client du service",
  success_toast_update_customer_service_info_catalog: "Vous avez modifié les informations du catalogue client du service avec succès",
  success_toast_create_customer_service_info_catalog: "Vous avez rajouté de nouvelles informations au catalogue client du service",
  success_toast_create_customer_service: "Le client a été rajouté au service avec succès",
  success_toast_validate_order: "La commande a été validée",
  success_toast_validate_orders: "Les commandes ont été validées",
  success_toast_staffing_request: "La demande de staffing a bien été envoyée",
  success_toast_staffing_validation: "La demande de staffing a bien été acceptée",
  success_toast_staffing_update: "La demande de staffing a bien été mise à jour",
  success_toast_delete_booking: "Vous avez supprimé la réservation avec succès",
  success_toast_create_purchase_order: "Vous avez créé une nouvelle commande d'achat avec succès",
  success_toast_create_booking: "Vous avez créé une nouvelle réservation avec succès",
  success_toast_update_booking: "Vous avez modifié la réservation avec succès",
  success_toast_update_purchase: "Vous avez modifié la commande d'achat avec succès",
  success_toast_purchases_created: "Les commandes d'achat ont bien été créées",
  success_toast_delete_generic: "Element supprimé avec succès",
  success_toast_activate_user: "Vous avez activé l'utilisateur avec succès",
  success_toast_deactivate_user: "Vous avez désactivé l'utilisateur avec succès",
  success_toast_update_user: "Vous avez modifié l'utilisateur avec succès",
  success_toast_create_user: "Vous avez créé l'utilisateur avec succès",
  success_toast_update_rule: "Vous avez modifié la règle d'utilisation avec succès",
  success_toast_create_rule: "Vous avez créé une règle d'utilisation avec succès",
  success_toast_activate_rule: "Vous avez activé la règle avec succès",
  success_toast_update_admin_settings: "Paramètres sauvegardés",
  succes_toast_deactivate_rule: "Vous avez désactivé la règle avec succès",
  success_delete_document: "Vous avez bien supprimé le document",
  success_toast_cancel_order_item: "Ligne de commande annulée avec succès",
  success_toast_edit_home_comment: "Note modifiée avec succès",
  success_toast_create_home_comment: "Note créée avec succès",
  success_toast_create_booking_from_order_item: "Les réservations ont été créées avec succès",
  success_toast_create_booking_from_order_item_none: "Pas de réservations à créer",
  success_toast_update_booking_document: "La réservation a été mise à jour avec succès",
  success_toast_booking_closed: "La réservation a été mise à jour et clôturée avec succès",
  success_toast_booking_cancelled: "La réservation a été annulée avec succès",
  success_toast_booking_keep: "La réservation a été maintenue avec succès",
  success_toast_add_order_item_file: "Le document a été ajouté avec succès",
  success_toast_add_booking_file: "Le document a été ajouté avec succès",
  success_toast_update_order: "La commande a été mise à jour avec succès",
  success_toast_delete_staffing: "Le staffing a bien été supprimé",
  success_toast_send_order_mail: "Le mail a bien été envoyé",
  success_toast_delete_bookings_to_do: "Les réservations ont bien été supprimées",
  success_toast_delete_supplier_service_catalog: "Vous avez supprimé les informations du catalogue fournisseur du service avec succès",
  success_toast_update_supplier_service_catalog: "Vous avez mis à jour les informations du catalogue fournisseur du service avec succès",
  success_toast_create_supplier_service_catalog: "Vous avez ajouté de nouvelles informations au catalogue fournisseur du service avec succès",
  success_toast_delete_activity_service: "Service retiré de la prestation",

  // TESTS
  popover: "Infobulle",
  fruits: "Fruits",
  radio: "Form Radio",
  upload: "Télécharger un fichier",

  // HEADER MENU
  header_menu_placeholder: "Client, ressource, commande...",
  header_menu_search_button: "Rechercher",
  header_menu_clear_search: "Réinitialiser",
  header_menu_add_user: "Ajouter un utilisateur",
  header_menu_create_button: "Créer",
  header_menu_user_button: "Utilisateur",
  header_profile_ADMIN: "Admin",
  header_profile_intern: "Interne",
  header_profile_resource: "Ressource",
  header_profile_customer: "Client",
  header_profile_tour_leader: "Tour Leader",
  header_profile_supplier: "Fournisseur",

  // LOGIN
  login_title: "Ravi de vous revoir !",
  login_subtitle: "Merci de renseigner vos informations de connexion.",
  email_placeholder: "Saisissez votre adresse e-mail",
  form_login_email: "Adresse e-mail",
  form_login_password: "Mot de passe",
  form_login_confirm_password: "Confirmation du mot de passe",
  password_placeholder: "Saisissez votre mot de passe",
  login_submit: "Me connecter",
  profile_title: "Bonjour ",
  profile_subtitle: "Sur quelle plateforme voulez-vous vous connecter ?",
  back: "Retour",
  access_platform: "Accéder à la plateforme ",
  TOUR_LEADER: "Tour Leader",
  ADMIN: "Administrateur",
  INTERN: "Employé",
  SUPPLIER: "Fournisseur",
  RESOURCE: "Ressource",
  CLIENT: "Client",
  BILLING_SERVICE: "Service de facturation",
  dropdown_header_logout: "Se déconnecter",
  dropdown_header_profile: "Mon profil",
  login_reset_password: "J'ai oublié mon mot de passe",
  send_mail_reset_password: "Envoyer le mail de réinitialisation",
  reset_password: "Réinitialiser votre mot de passe",
  login_rule_download: "Télécharger le document associé",
  login_rule_accept: "J'ai lu et j'accepte les règles",
  login_rule_title: "Règles d'utilisation",
  login_rule_subtitle: "Merci d'accepter les points suivants avant de continuer",

  // MOT DE PASSE OUBLIE
  forgotten_password_title: "Mot de passe oublié",
  forgotten_password_subtitle: "Merci de renseigner votre adresse e-mail afin que nous vous envoyons un lien de réinitialisation de mot de passe.",
  new_password_title: "Nouveau mot de passe",
  new_password_subtitle: "Merci de renseigner votre nouveau mot de passe.\n" +
    "Celui-ci doit contenir : \n" +
    "- au moins 8 caractères, \n" +
    "- au moins 1 majuscule et 1 minuscule, \n" +
    "- au moins 1 chiffre, \n" +
    "- au moins 1 caractère spécial parmi #?!@$%^&*-.",
  token_expired: "Le lien n'est pas reconnu ou expiré, veuillez (re)faire la procédure de mot de passe oublié",

  // OPTIONS
  yes_option: "Oui",
  no_option: "Non",
  female_option: "Féminin",
  male_option: "Masculin",
  EMPLOYEE: "Salarié",
  INDEPENDENT: "Indépendant",
  JUNIOR: "Junior",
  SENIOR: "Sénior",
  ALL: "Toutes",
  LOW: "Basse",
  MEDIUM: "Moyenne",
  HIGH: "Élevée",
  ZONE: "Zone",
  public_type_group: "Groupe",
  public_type_private: "Privé",
  public_type_kids_friendly: "Kids friendly",

  // IDENTITY
  name_field: "Nom",
  phone_number_field: "Numéro de téléphone",
  email_field: "Email",

  // ADDRESS
  address_data_gouv_error: "Aucune adresse correspondant à votre recherche n'a été trouvé",
  search_text: "Recherche en cours",
  address_not_found: "Je ne trouve pas mon adresse",
  address_placeholder: "Rechercher une adresse",
  country_placeholder: "Rechercher un pays",
  address_field: "Adresse",
  additional_address_field: "Complément d'adresse",
  service_field: "Service",
  zip_code_field: "Code postal",
  city_field: "Ville",
  country_field: "Pays",

  // TABS
  info_tab: "Informations",
  details_tab: "Détails",
  activity_monitoring_tab: "Suivi d'activités",
  contact_tab: "Contacts",
  documents_tab: "Documents",
  setting_invoicing_tab: "Paramètres et facturation",
  activity_tab: "Prestations",
  supplier_tab: "Fournisseurs",
  service_tab: "Services",
  professional_info_tab: "Informations professionnelles",
  bank_accounts_tab: "Données bancaires",
  legal_info_tab: "Renseignements légaux",
  activities_tab: "Prestations maîtrisées",
  linked_activities_tab: "Prestations liées",
  required_resources_tab: "Ressources requises",

  // BUTTONS
  save_button: "Enregistrer",
  cancel_button: "Annuler",
  validate_button: "Valider",
  send_button: "Envoyer",
  confirm_button: "Confirmer",
  create_contact_button: "Créer un contact",
  add_button: "Ajouter",
  edit_button: "Modifier",
  delete_button: "Supprimer",
  revoke_button: "Retirer",
  action_button: "Action",
  next_button: "Suivant",
  previous_button: "Précédent",
  choose_button: "Sélectionner",
  export_button: "Exporter",
  close_button: "Fermer",

  // INFORMATION MESSAGE
  info_message_init_password: "Un email avec un lien permettant d’initialiser le mot de passe du compte sera envoyé à l’utilisateur",

  //WORKFLOW
  workflow_entity_tour: "tour",
  workflow_entity_activity: "prestation",
  workflow_entity_service: "service",
  workflow_entity_resource: "ressource",
  workflow_entity_supplier: "fournisseur",
  workflow_entity_tour_leader: "tour leader",
  workflow_entity_client: "client",
  workflow_delete: "Supprimer la fiche {entity}",
  workflow_validate: "Valider la fiche {entity}",
  workflow_validate_self: "Valider votre fiche",
  workflow_deactivate: "Désactiver la fiche {entity}",
  workflow_reactivate: "Ré-activer la fiche {entity}",
  workflow_delete_info: "La fiche {entity} a bien été supprimée",
  workflow_validate_info: "La fiche {entity} a bien été activée",
  workflow_validate_info_self: "Votre fiche a bien été activée",
  workflow_deactivate_info: "La fiche {entity} a bien été désactivée",
  workflow_reactivate_info: "La fiche {entity} a bien été ré-activée",
  workflow_delete_failure: "La fiche {entity} n'a pas pu être supprimée",
  workflow_validate_failure_self: "Votre fiche n'a pas pu être activée. Vérifiez que tous les champs requis ont bien été renseignés",
  workflow_validate_failure: "{entity} n'a pas pu être activée. Vérifiez que tous les champs requis ont bien été renseignés",
  workflow_deactivate_failure: "La fiche {entity} n'a pas pu être désactivée",
  workflow_reactivate_failure: "La fiche {entity} n'a pas pu être ré-activée. Vérifiez que tous les champs requis ont bien été renseignés",
  workflow_created: "Créé",
  workflow_active: "Actif",
  workflow_inactive: "Inactif",
  status: "Statut",
  state_filter_all: "Tous",
  state_filter_active: "Actif",
  state_filter_inactive: "Inactif",
  state_filter_created: "Créé",

  // INVOICE
  invoicing: "Facturation",
  customer_individual_field: "Particulier",
  customer_geographical_zone_field: "Zone géographique du client",
  eu_customer: "Client UE",
  outside_eu_customer: "Client Hors UE",
  french_customer: "Client FR",
  customer_number_intra_vat_field: "N° TVA Intracommunautaire",
  customer_invoice_field: "Facture pour tour opérateur",
  customer_billing_services_field: "Services de facturation",
  customer_default_billing_service_field: "Service de facturation par défaut",
  customer_disbursement_field: "Débours",
  customer_voucher_management_field: "Gestion du suivi voucher Client",
  customer_main_sort_invoice_field: "Tri principal sur les factures",
  customer_siret_field: "SIRET / N° Société",
  customer_secondary_sort_invoice_field: "Tri secondaire sur les factures",
  activity_date: "Date de prestation",
  order_code: "N° commande",
  order_button_bill: "Facturer",
  activity_name: "Nom prestation",
  customer_activity_price: "Prix prestation",
  create_new_invoice: "Facture",
  invoice_customer:"Client facturé",
  invoice_date: "Date de la facture",
  invoice_show_to_customer: "Visibilité de la facture par le client",
  success_toast_create_invoice:"La facture a bien été créée",
  success_toast_update_invoice:"La facture a bien été modifiée",
  success_toast_update_invoice_contact:"Le contact a bien modifiée",
  success_toast_update_show_to_customer_toast: "La visibilité de la facture pour le client a bien été modifiée",
  erreur_toast_update_show_to_customer_toast: "Erreur lors du changement de visibilité de la facture pour le client",
  error_toast_create_invoice:"Une erreur est survenue lors de la création de la facture",
  error_toast_update_invoice:"Une erreur est survenue lors de la modification de la facture",
  error_toast_update_invoice_contact:"Une erreur est survenue lors de la mdofication du contact",
  error_toast_delete_bookings_to_do:"Une erreur est survenue lors de la suppression des réservations",
  dropdown_header_invoice: "Facture",
  invoice_billing_item_order_item: "Ligne de commande",
  invoice_voucher: "Vouchers",
  invoice_tickets: "Tickets",
  invoice_files_error: "Erreur au chargement des fichiers",

  // OPERATION
  operation: "Opérations",
  customer_reservation_management_field: "Gestion des réservations",
  customer_public_type: "Type de public",
  customer_number_weeks_viewed_field: "Nombre de semaines visualisées",
  customer_notification_activity_field: "Informer des autres prestations",
  customer_notification_replacement_field: "Notification des remplacements",

  // MODALS
  modal_title_update_access_platform: "Modifier l'accès à la plateforme",
  modal_body_update_access_platform: "Souhaitez-vous donner l'accès à la plateforme au contact {lastName} {firstName} ?",
  modal_title_delete_access_platform: "Supprimer le contact",
  modal_body_delete_access_platform: "Souhaitez-vous supprimer le contact {lastName} {firstName} ?",
  modal_title_update_main_contact: "Modifier le contact principal",
  modal_body_update_main_contact: "Souhaitez-vous que le contact {lastName} {firstName} soit le contact principal ?",
  modal_title_delete_activity: "Supprimer une prestation du tour",
  modal_body_delete_activity: "Souhaitez-vous supprimer la prestation {activity} du tour ?",
  modal_title_delete_bank_account: "Supprimer un compte bancaire",
  modal_body_delete_bank_account: "Souhaitez-vous supprimer le compte bancaire {accountName} ?",
  modal_title_update_default_bank_account: "Modifier le compte bancaire par défaut",
  modal_body_update_default_bank_account: "Souhaitez-vous que le compte bancaire {name} soit le compte bancaire par défaut ?",
  modal_title_delete_selling_price: "Supprimer un prix de vente",
  modal_body_delete_selling_price: "Souhaitez-vous supprimer le prix de vente ?",
  modal_title_delete_resource_rate: "Supprimer un tarif ressource",
  modal_body_delete_resource_rate: "Souhaitez-vous supprimer le tarif ressource ?",
  modal_title_delete_customer_service_info_catalog: "Supprimer cette information du catalogue client",
  modal_body_delete_customer_service_info_catalog: "Souhaitez-vous supprimer les informations du catalogue client pour le service ?",
  modal_title_delete_booking: "Supprimer une réservation",
  modal_body_delete_booking: "Souhaitez-vous supprimer la réservation {serviceName} ?",
  modal_title_delete_document: "Supprimer un document",
  modal_body_delete_document: "Êtes-vous sur de bien vouloir supprimer le document ?",
  modal_body_delete_order_item: "Souhaitez-vous supprimer la ligne de commande ?",
  modal_body_delete_quote_item: "Souhaitez-vous supprimer la ligne de devis",
  modal_body_delete_quote_item_title: "Supprimer une ligne de devis",
  modal_body_cancel_order_item: "Souhaitez-vous annuler ou supprimer la ligne de commande ?",
  modal_body_cancel_order_item_cannot_delete: "Vous ne pouvez pas supprimer la ligne, au moins un staffing ou une réservation est en cours",
  modal_body_delete_order:  "Souhaitez-vous supprimer la commande ?",
  modal_body_delete_order_cannot_delete: "Vous ne pouvez pas supprimer cette commande car au moins une ligne de commande a un statut confirmé, est associé à un staffing, une réservation ou une facturation.",
  modal_body_cancel_order:  "Souhaitez-vous annuler la commande ?",
  modal_title_delete_staffing: "Suppression staffing",
  modal_body_delete_staffing: "Souhaitez-vous supprimer ce staffing ?",
  modal_title_delete_supplier_service_catalog: "Supprimer cette information du catalogue fournisseur",
  modal_body_delete_supplier_service_catalog: "Souhaitez-vous supprimer les informations du catalogue fournisseur pour le service ?",
  modal_title_delete_activity_service: "Supprimer le service",
  modal_body_delete_activity_service: "Voulez vous supprimer le service pour cette prestation ?",
  modal_title_validate_orders: "Valider les commandes",
  modal_body_validate_orders: "Souhaitez-vous valider les commandes ?",
  modal_title_cancel_staffing_item: "Êtes vous sûr de vouloir annuler ce staffing ?",
  modal_body_cancel_staffing_item: "Les guides ayant été contactés recevront un mail d’annulation de la demande.",
  modal_remind_voucher_staffing: "Relancer la ressource",
  modal_deny_voucher_order_item: "Refuser le voucher",

  // COMMENT
  comment_added_by: "Ajouté le {date} par {firstName} {lastName}",
  comment_toast_success_creation: "Le commentaire a été ajouté avec succès",
  comment_toast_error_creation: "Une erreur est survenue lors de l'ajout du commentaire",
  comment_toast_success_update: "Le commentaire a été mis à jour avec succès",
  comment_toast_error_update: "Une erreur est survenue lors de la mise à jour du commentaire",
  comment_toast_success_deletion: "Le commentaire a bien été supprimé",
  comment_toast_error_deletion: "Une erreur est survenue lors de la suppression du commentaire",

  // TABLES
  table_actions: "Actions",
  table_size: "Taille de la page",
  table_see_all_details: "Affichage étendu :",

  // STAFFING
  staffing_title: "Staffing",
  staffing_staff: "Staffer",
  staffing_order: "Commande",
  staffing_order_name: "Nom de la commande",
  staffing_activity: "Prestations",
  staffing_date: "Date",
  staffing_date_hour: "Date - heure",
  staffing_hour: "Heure",
  staffing_place: "Lieu de rendez-vous",
  staffing_resources: "Ressources requises",
  staffing_tour: "Tour",
  staffing_activity_area: "Zone géographique",
  staffing_pending: "A effectuer",
  staffing_in_progress: "En cours",
  staffing_in_done: "Confirmé",
  staffing_search_order: "Rechercher une commande",
  staffing_search_order_placeholder: "Commande client",
  staffing_order_item: "Prestation",
  staffing_resource: "Ressource",
  staffing_duration: "Durée",
  staffing_customer_reference: "Référence client",
  staffing_supplier: "Fournisseur",
  staffing_service: "Service",
  staffing_customer: "Client",
  staffing_from: "Date de commande du",
  staffing_to: "Date de commande jusqu'au",
  staffing_availability: "Disponibilité",
  staffing_resource_name: "Nom complet",
  staffing_add_message: "Ajouter un message",
  staffing_edit_message: "Modifier le message",
  staffing_edit_general_message: "Renseigner un message commun",
  staffing_general_message: "Message commun",
  staffing_message_placeholder: "Taper votre message ici...",
  staffing_confirm: "Confirmer",
  staffing_available: "Disponible",
  staffing_available_confirmed: "{count} disponibilités confirmées",
  staffing_available_other_confirmed: "Disponibilité confirmée sur une autre prestation",
  staffing_not_available: "Non disponible",
  staffing_refuse: "Refuser",
  staffing_selected_number: "{selectedNumber} séléctionné(s)",
  staffing_send_request: "Envoyer demande de staffing",
  staffing_send_request_multiple: "Demande de staffing manuelle",
  staffing_request_title: "Mes prestations",
  staffing_request_modal_body: "Une demande de staffing va  être envoyée pour les activitées suivantes :",
  staffing_request_modal_body_confirm: "Confirmez vous l'opération ?",
  staffing_request_validated: "Votre disponibilité pour la prestation {name} a bien été prise en compte",
  staffing_request_cancelled: "La prestation a été annulée, vous ne pouvez plus l'accepter",
  staffing_request_direct: "Ressource(s) confirmé(s)",
  staffing_request_renew: "Relancer staffing",
  staffing_request_cancel: "Annuler staffing",
  staffing_request_cancel_button: "Annuler staffing",
  staffing_request_back_button: "Retour",
  staffing_request_modal_cancel_body: "Êtes vous sûr de vouloir annuler ce staffing ? \n Les guides ayant été contactés recevront un mail d’annulation de la demande",
  staffing_renew: "Relancer",
  staffing_check: "Vérifier",
  staffing_see: "Afficher",
  staffing_staffed: "Etat staffing",
  staffing_request_to_replace: "Ressource à remplacer",
  staffing_request_replacement: "Nouvelle ressource",
  staffing_sent: "Demande envoyée",
  staffing_confirmed: "Confirmé",
  staffing_cancelled: "Demande archivée",
  staffing_refused: "Demande refusée",
  staffing_request_new_request_title: "Demande de disponibilité ({count})",
  staffing_request_awaiting_request_title: "Prestation en attente de confirmation ({count})",
  staffing_request_confirmed_request_title: "Prestation confirmée ({count})",
  staffing_request_number_available: "{count} réponse(s)",
  staffing_ongoing_staffing: "Un staffing est déjà en cours pour cette prestation",
  staffing_no_results: "Aucune ressource disponible correspondant aux filtres",
  staffing_notification: "Vous êtes sur le point de renvoyer un e-mail, en êtes-vous sûr ?",

  // BILLING OVERVIEW
  billing_overview_title: "Suivi factures ressources",
  billing_overview_number: "N° de facture",
  billing_overview_number_placeholder: "Numéro...",
  billing_overview_creation_date: "Soumise le",
  billing_overview_resource: "Ressource",
  billing_overview_period_start: "De",
  billing_overview_period_end: "A",
  billing_overview_name: "Nom",
  billing_overview_date: "Date facture",
  billing_overview_payment_date: "Date de paiment",
  billing_overview_period: "Période",
  billing_overview_amount_bill: "Montant facture HT",
  billing_overview_amount_bill_TTC: "Montant facture TTC",
  billing_overview_amount_activities: "Montant prestations HT",
  billing_overview_download: "Téléchargement",
  billing_overview_detail_tour_code: "Code tour",
  billing_overview_detail_activity: "Prestation",
  billing_overview_detail_duration: "Durée",
  billing_overview_detail_client: "Client",
  billing_overview_detail_ref_client: "Référence client",
  billing_overview_detail_date_hour: "Date - heure",
  billing_overview_detail_price: "Montant",
  billing_overview_bills: "{count, plural, one {# Facture} other {# Factures}}",
  billing_overview_total_amount: "Total facturé: {total}€",
  billing_overview_hour: "{count, plural, one {# heure} other {# heures}}",
  billing_overview_day: "{count, plural, one {# jour} other {# jours}}",
  billing_overview_nb_activities: "Nbre prestations",

  // FILTERS
  autocomplete_results_size: "{total} de plus...",
  autocomplete_more_than_one_selected: "{size} élément(s) sélectionné(s)",
  filter_from: "De",
  filter_to: "À",
  filter_all: "Tout sélectionner {selected}",

  // BOOKING
  bookings_title: "Réservations",
  booking_filter_supplier: "Fournisseur",
  booking_filter_service: "Service",
  booking_filter_order: "Référence commande",
  booking_filter_order_placeholder: "N° commande ou ref client",
  booking_filter_customer: "Client",
  booking_order_reference: "Référence commande",
  booking_default_supplier: "Fournisseur par défaut",
  booking_date_hour: "Date",
  booking_order_pax: "PAX commande",
  booking_pax: "PAX réservation",
  booking_quantity: "Qté à réserver",
  booking_supplier: "Fournisseur",
  booking_comment: "Commentaire",
  booking_price: "Prix",
  booking_service: "Service",
  booking_customer: "Client",
  booking_order_number: "N° de commande",
  booking_date: "Date réservation",
  booking_hour: "Heure réservation",
  booking_create_purchases: "Créer les commandes d'achat",
  booking_request_modal_body: "Les commandes suivantes vont être passées :",
  booking_to_do: "A effectuer",
  booking_add_bill_file: "Ajouter une facture",
  booking_add_payment_file: "Ajouter un réglement",
  booking_add_confirmation_file: "Ajouter un justificatif",
  booking_add_ticket_file: "Ajouter un billet",
  booking_add_other_file: "Ajouter un autre fichier",
  booking_pending: "En cours",
  booking_actions: "Actions requises",
  booking_only_quantity: "Quantité",
  booking_open: "Ouvertes",
  booking_waiting: "En attente confirmation",
  booking_confirmed: "Confirmé, réglé",
  booking_to_pay: "Confirmé, à régler",
  booking_warning: "Alerte",
  booking_delete_to_do: "Supprimer les réservations",
  booking_body_single_delete_to_do: "Cette réservation va être supprimée",
  booking_body_multiple_delete_to_do: "Les réservations suivantes vont être supprimées :",
  booking_pay_modal_title: "Paiement de réservations",
  booking_pay_modal_body: "Voulez-vous passer cette (ces) réservaton(s) en règlement ?\n\n Une fois l'opération réalisée, un fichier SEPA XML sera généré et les réservations en question seront considérées comme réglées.",
  booking_pay_modal_success: "Les réservations ont bien été réglées",
  booking_pay_modal_failure: "Une erreur est survenue lors du règlement des réservations",
  booking_reference: "N° de réservation",
  workflow_booking_to_do: "A réserver",
  workflow_booking_open: "A envoyer",
  workflow_booking_pre_booked: "Pré-réservée",
  workflow_booking_document_received: "Confirmée",
  workflow_booking_closed: "Terminée",
  workflow_booking_to_update: "A modifier",
  workflow_booking_to_cancel: "A annuler",
  workflow_booking_cancelled: "Annulée",
  booking_validation_status_change: "Valider la modification de la réservation",

  // PURCHASE
  purchases_title: "Commandes d'achat",
  purchase_booking_filter_title: "Rechercher une réservation",
  purchase_filter_customer_order_placeholder: "N° commande",
  purchase_header_close: "Cloturées",
  purchase_order_code: "N° commande",
  purchase_order_date: "Date",
  purchase_order_creation: "Créée le",
  purchase_order_supplier: "Fournisseur",
  number_booking_validated: "Nb réservations",
  number_order_document: "Nb documents",
  purchase_booking: "Réservation",
  purchase_booking_quantity: "Qté",
  purchase_booking_price: "Total",
  purchase_booking_status_unknown: "Inconnu",
  purchase_booking_billed: "Facturé",
  purchase_booking_paid: "Réglé",
  purchase_order_number: "Commande d'achat N°",
  purchase_list_attachments: "Liste des documents joints",
  purchase_download: "Télécharger le XLSX",
  purchase_send_order: "Envoyer la commande",
  purchase_delete_order: "Supprimer la commande",
  purchase_pay_bookings: "Régler les réservations non réglées",
  purchase_order_booking_empty: "Votre commande d'achat ne contient aucune réservation",
  purchase_booking_service: "Service",
  purchase_booking_price_net: "PU achat Net/HT",
  purchase_booking_total: "Total",
  purchase_booking_vat: "TVA",
  purchase_booking_total_TTC: "Total TTC",
  purchase_booking_total_HT: "Total HT",
  purchase_booking_action: "Actions",
  purchase_booking_order_number: "Commande",
  purchase_booking_pax: "PAX",
  purchase_booking_comment: "Commentaire",
  purchase_booking_status: "Statut",
  purchase_booking_disbursement: "A facturer",
  purchase_booking_number: "N° réservation",
  purchase_booking_confirmation_date: "Date de confirmation",
  purchase_booking_payment_alert_date: "Date d'alerte règlement",
  purchase_booking_invoice_date: "Date de facturation",
  purchase_booking_order: "Commande",
  purchase_booking_order_item: "Ligne de commande",
  purchase_booking_add: "Ajouter une réservation",
  purchase_booking_update: "Modifier une réservation",
  purchase_attached_file_title: "Document(s)",
  purchase_no_attached_file: "Aucun document",
  purchase_order_comment: "Commentaires",
  purchase_delete_modal_title: "Suppression de la commande d'achat",
  purchase_delete_modal_send_mail: "Envoyer un email au fournisseur",
  purchase_delete_modal_message_label: "Message :",
  purchase_delete_modal_info: "Êtes-vous sûr de vouloir supprimer la commande d'achat {number} ?",
  purchase_send_mail: "Envoyer un mail au fournisseur",
  modal_send_purchase_title: "Envoi de la commande d'achat",
  modal_send_purchase_contact: "Contact",
  success_toast_add_purchase_attached_file: "Le document a été ajouté avec succès",
  error_toast_update_purchase_attached_file: "Erreur lors de l'ajout d'un fichier",
  success_toast_delete_purchase: "La commande d'achat {number} a bien été supprimée",
  error_toast_delete_purchase: "Une erreur est survenue lors de la suppression de la commande d'achat {number}",
  new_purchase_order: "Nouvelle commande d'achat",
  create_purchase_order: "Créer une commande d'achat",
  button_selected_number: "{selectedNumber} séléctionnée(s)",
  workflow_purchase_open: "Ouverte",
  workflow_purchase_sent: "Envoyée",
  workflow_purchase_closed: "Clôturée",

  // INVOICES
  invoice_filter_all: "Toutes les factures",
  invoice_filter_draft: "Brouillon",
  invoice_filter_billed: "Facturés",
  invoice_filter_number: "Rechercher une facture",
  invoice_filter_number_placeholder: "N° de facture, client",
  invoice_filter_type: "Type",
  invoice_filter_status: "Statut",
  INVOICE: "Facture",
  CREDIT_NOTE: "Avoir",
  BILLED: "En attente du réglement",
  PAID: "Facturée et réglé",
  LATE: "Délais dépassés",
  invoice_number: "N° de facture",
  invoice_customer_name: "Client facturé",
  invoice_created_date: "Date de création",
  invoice_period: "Période",
  invoice_price_ttc: "Prix TTC (€)",
  invoice_price_ht: "Prix HT (€)",
  invoice_tva: "TVA (%)",
  invoice_statut: "Statut",
  invoice_name_order: "Client donneur d’ordre",
  invoice_title: "Factures",
  invoice_details: "Voir détails",
  invoice_remind: "Relancer client",
  invoice_download_pdf: "Télécharger la facture",
  invoice_header_title: "Factures",
  invoice_header_date_remaining: "À régler avant le ",
  invoice_header_date_fully_paid: "Réglé le ",
  invoice_header_date_late_payment: "Retard de {days} jours",
  invoice_header_remind_customer: "Relancer le client ? ",
  invoice_state_draft: "Brouillon",
  invoice_state_billed: "En attente du réglement",
  invoice_state_paid: "Facturée et réglée",
  invoice_state_late: "Délai dépassé",
  invoice_validate: "Valider la facture",
  invoice_delete: "Supprimer la facture",
  billing_tab_invoices_to_create: "Facturation de vente",
  billing_invoices_create: "Créer une facture",
  billing_invoices_export: "TVA sur marge",
  billing_invoices_export_body: "La situation de TVA sur marge sera exportée pour le client {client} sur la période du {from} au {to}",
  billing_invoices_export_show_details: "Avec détail des prestations :",
  billing_tab_invoices: "Factures",
  billing_generate_preinvoice: "Résumé de pré-facturation",
  billing_generate_preinvoice_success: "Les pré-factures ont bien été générées.",
  billing_generate_preinvoice_error: "Erreur lors de la génération des pré-factures.",
  billing_generate_invoice_modal_info: "Vous allez déclencher une pré-facturation des lignes de commande selectionnées",
  billing_generate_invoice_modal_summary: "Cette opération va créer des pré-factures pour les clients suivants:",
  invoice_billing_item_title_add: "Ajouter une ligne de facturation",
  invoice_billing_item_edit_contact: "Modifier le contact",
  invoice_billing_item_header_date: "Date",
  invoice_billing_item_header_service: "Service",
  invoice_billing_item_header_title: "Qté",
  invoice_billing_item_header_resource: "Nb guides",
  invoice_billing_item_header_unit_price: "Prix unitaire",
  invoice_billing_item_header_price: "Prix HT",
  invoice_billing_item_header_priceTTC: "Prix TTC",
  invoice_billing_item_header_paid: "Réglé",
  invoice_billing_item_header_remaining: "Restant dû",
  invoice_billing_item_header_credit_note: "Avoir N°{credit_note} associé au paiment",
  invoice_header_total_ht: "Prix total HT",
  invoice_header_total_tva: "TVA",
  invoice_header_total_disbursement: "Total débours",
  invoice_header_total_ttc: "Prix total TTC",
  invoice_header_total: "Total à payer",
  invoice_header_total_paid: "Réglement effectué",
  invoice_header_total_remaining: "Solde restant dû",
  invoice_download: "Télécharger le PDF",
  billing_item_additionnal_charge: "Frais additionnels",
  invoice_modal_remind_customer: "Relancer le client",
  invoice_mail_remind_customer_greetings: "Madame, Monsieur,",
  invoice_mail_remind_customer_body_1: "Sauf erreur de notre part, la facture {invoiceNumber} du {invoiceDate}, à échéance au {dueDate} n’est, à ce jour, pas réglée en totalité et un solde de {amountRemaining} reste dû.",
  invoice_mail_remind_customer_body_2: "Nous vous remercions de votre mise en règlement de ce solde dans les meilleurs délai.",
  invoice_mail_remind_customer_body_3: "Pour rappel à compter de la date d’émission de la facture et jusqu’à complet paiement, tout client ayant un retard de paiement supérieur à la date d’échéance engage sa responsabilité auprès de l’Administration.",
  invoice_mail_remind_customer_body_4: "En cas de retard de paiement, une indemnité forfaitaire pour frais de recouvrement due au créancier peut-être appliquée conformément à l’article de loi n°2012-387 du 22 mars 2012. Cette indemnité est fixée à 40€ par le décret n°2012-1115 du 2 octobre 2012.",
  invoice_mail_remind_customer_body_5: "Dans l’attente de votre règlement,",
  invoice_mail_remind_customer_bye: "Cordialement",
  invoice_mail_remind_customer_toast_success: "Email envoyé",
  invoice_mail_remind_customer_toast_error: "Un problème est survenue lors de l'envoi du mail",

  // CREDIT NOTES
  modal_title_credit_note: "Générer un avoir",
  modal_body_credit_note: "Voulez vous générer un avoir sur la facture {invoiceNumber} ?",
  modal_body_credit_note_billing_items: "Lignes de facture à inclure :",
  modal_body_credit_note_select_all: "Tout sélectionner",
  modal_body_credit_note_unselect_all: "Tout déselectionner",
  credit_note_header_title: "Avoirs",
  go_to_invoice: "Voir la facture",
  go_to_order: "Aller vers la commande associée",
  go_to_credit_note: "Voir l'avoir",
  credit_note_download: "Télécharger le PDF",
  credit_note_validate: "Valider l'avoir",
  credit_note_delete: "Supprimer l'avoir",
  credit_note_billing_item_title_add: "Ajouter une ligne d'avoir",
  credit_note_state_billed: "Validé",
  invoice_credit_note: "Générer un avoir",
  success_toast_update_credit_note:"L'avoir a bien été modifiée",
  success_toast_create_credit_note:"L'avoir a bien été créé",
  error_toast_create_credit_note:"Une erreur est survenue lors de la création de l'avoir",
  error_toast_update_credit_note:"Une erreur est survenue lors de la mdofication de l'avoir",

  // STATUS
  TO_DO: "A faire",
  OPEN: "Ouverte",
  PRE_BOOKED: "Pré-réservée",
  PENDING_RECEIPT: "En attente de réception",
  DOCUMENT_RECEIVED: "Confirmée",
  STATUS_DEFAULT: "Inconnu",
  TO_UPDATE: "A modifier",
  TO_CANCEL: "A annuler",
  CANCELLED: "Annulée",
  CLOSED: "Clôturée",

  // ADMINISTRATION
  admin_title: "Administration",

  // ADMIN CONFIGURATION
  config_tab: "Tableau de bord",
  config_tab_mail: "Email",
  admin_config_dashboard_booking_todo: "Nb de jours pour la période des réservations à faire",
  admin_config_dashboard_booking_todo_tooltip: "Période (de la date du jour jusqu'au nombre de jour renseigné) sur laquelle sera compté le nombre de réservations à effectuer (\"Besoins de réservation\") dans le tableau de bord interne",
  admin_config_dashboard_booking_pending: "Nb de jours pour la période des réservations à confirmer",
  admin_config_dashboard_booking_pending_tooltip: "Période (de la date du jour jusqu'au nombre de jour renseigné) sur laquelle sera compté le nombre de \"Réservations à confirmer\" dans le tableau de bord interne",
  admin_config_dashboard_staffing: "Nb de jours pour la période des prestations à staffer",
  admin_config_dashboard_staffing_tooltip: "Période (de la date du jour jusqu'au nombre de jour renseigné) sur laquelle sera compté le nombre de \"Prestations à staffer\" dans le tableau de bord interne",
  admin_config_booking_prebooking_delay: "Délai de pré-réservation du fournisseur",
  admin_config_booking_prebooking_delay_tooltip: "Délai à partir duquel l'alerte \"A envoyer avant X jours\" dans le suivi des réservations est déclenchée, elle indique ensuite le nombre de jours qu'il reste avant le jour correspondant au délai de pré-réservation du fournisseur",
  admin_config_booking_service_delay: "Nb de jours avant le service",
  admin_config_booking_delta_minutes: "Mettre à jour le delta en minutes",
  admin_config_booking_delta_minutes_tooltip: "Temps (minutes) par défaut entre l'heure de RDV et de départ",
  admin_config_booking_service_delay_tooltip: "Délai à partir duquel l'alerte \"Le service a lieu dans X jours\" sur le suivi des réservations est déclenchée",
  admin_config_mail_signature: "Signature des mails",
  admin_config_mail_contact: "Email de contact",
  admin_config_mail_signature_tooltip: "Signature globale appliquée à la fin de chaque mail",

  // HOME COMMENTS
  home_comment_config_tab: "Notes à l'accueil",
  home_comment_no_result: "Aucune note n'a été trouvée",
  home_comment_date: "Date",
  home_comment_content: "Contenu",
  home_comment_user: "Créateur",
  home_comment_create: "Ajouter une note",
  home_comment_edit: "Modifier la note",

  // RULES
  rules_tab: "Règles d'utilisation",
  rules_table_header_profiles: "Profil(s)",
  rules_table_header_description: "Règle",
  rules_table_header_status: "Statut",
  rules_no_result: "Il n'y a aucune règle d'utilisation",
  rules_table_all_profiles: "Tous",
  rules_table_status_active: "Active",
  rules_table_status_inactive: "Inactive",
  edit_rule: "Modifier une règle",
  create_rule: "Créer une règle",
  rule_profiles: "Profil(s)",
  rule_description: "Description",
  rule_attachment: "Document associé",
  PROFILE_ADMIN: "Administrateur",
  PROFILE_INTERN: "Interne",
  PROFILE_RESOURCE: "Ressource",
  PROFILE_CUSTOMER: "Client",
  PROFILE_TOUR_LEADER: "Tour leader",
  PROFILE_SUPPLIER: "Fournisseur",

  // DOCUMENTARY BASE
  documentary_base_title: "Base documentaire",
  documentary_base_table_column_name: "Nom du fichier",
  documentary_base_table_column_activities: "Prestations associées",
  documentary_base_table_column_profiles: "Profils",
  documentary_base_filter_name: "Nom de fichier",
  documentary_base_filter_name_placeholder: "Nom de fichier...",
  documentary_base_filter_activity: "Prestation",
  documentary_base_filter_profile: "Profil",
  documentary_base_filter_theme: "Theme",
  documentary_base_no_result: "Il n'y a pas de documents",
  documentary_base_add_document: "Ajouter un document",
  documentary_base_form_profiles: "Afficher pour les profils",
  documentary_base_form_theme: "Theme",
  documentary_base_form_activities: "Prestations",
  documentary_base_checkbox_activities: "Spécifique à des prestations",
  documentary_base_checkbox_activityArea: "Spécifique à une zone géographique",
  documentary_base_checkbox_customer: "Spécifique à un client",
  documentary_base_form_add_title: "Ajout d'un document",
  documentary_base_form_edit_title: "Modification d'un document",
  documentary_base_form_create_success: "Le document a bien été créé",
  documentary_base_form_create_failure: "Erreur lors de la création du document",
  documentary_base_form_edit_success: "Le document a bien été modifié",
  documentary_base_form_edit_failure: "Erreur lors de la modification du document",
  FILE_THEME_OPERATIONS: "Opérationnel",
  FILE_THEME_GUIDED_TOUR: "Visite Guidée",
  FILE_THEME_POW_CUSTOMER_DEMANDS: "Exigences Paris on the Way Clients",
  FILE_THEME_BILLING_ADMIN_IT_PROCESSES: "Facturation, Administratif, Informatique, Processus",
  FILE_THEME_CONSULTING_FEEDBACK_SECURITY: "Conseils / Retour expérience & Sécurité",
  FILE_THEME_MISCELLANEOUS: "Divers",
  FILE_THEME_NONE: "Sans theme",

  // BOOKING WARNINGS
  booking_open_warning: "A envoyer avant {days} jours",
  booking_pre_booked_warning: "Le service a lieu dans {days} jours",
  booking_confirmed_warning: "Documents manquants",
  booking_to_pay_warning: "A régler avant {days} jours",
  booking_created_after_order_item_update: "Créée après modification de la prestation",

  // NOTIFICATION
  notification_staffing_assigned: "{count, plural, one {# nouvelle demande} other {# nouvelles demandes}}",
  notification_staffing_assigned_title: "Nouvelles demandes de disponibilité",
  notification_order_item_no_supplier: "Pas de fournisseur disponible pour l'un des services dans la ligne de commande  {name}",
  notification_order_item_no_supplier_title: "Pas de fournisseur disponible",
  notification_profile_title: "Profil incomplet",
  notification_profile: "Votre profil est incomplet",
  notification_activity_updated_title: "Prestation modifiée",
  notification_activity_updated: "{code} | {date} | {name}",
  notification_activity_schedule_changed: "Nouvelle heure de RDV",
  notification_activity_place_changed: "Nouveau lieu de RDV",
  notification_activity_pax_changed: "Changement de PAX",
  notification_activity_description_changed: "La description a été modifiée",
  notification_delete_error: "Une erreur est survenue lors de la suppression d'une notification",
  notification_order_item_bookings_to_update_title: "Réservations à modifier",
  notification_order_item_bookings_to_update: "Réservations de la ligne de commande {name} à modifier",
  notification_booking_payment_alert_title: "Réservation à régler",
  notification_booking_payment_alert: "Réservation {name} à régler avant le {bookingDate}",
  notification_booking_todo_alert_title: "Réservation à faire",
  notification_booking_todo_alert: "Réservation {name} à faire avant le {bookingDate}",
  notification_order_item_incomplete_alert_title: "Urgence prestation",
  notification_order_item_incomplete_alert: "La prestation {name} qui doit avoir lieu le {rendezVousDate} n'est pas complète",
  notification_order_item_bookings_to_cancel_alert_title: "Réservations à annuler",
  notification_order_item_bookings_to_cancel_alert: "La prestation {name} de la commande {customerReference} a été annulée",
  notification_activity_billing_new_comment_title: "Nouveau commentaire",
  notification_activity_billing_new_comment: "Vous avez un nouveau commentaire pour la facture {number}",


  // BOOKING FORM
  booking_form_client_order: "Commande client",
  booking_form_purchase_order: "Commande d'achat",
  booking_form_supporting: "Justificatif",
  booking_form_bill: "Facture",
  booking_form_payment: "Règlement",
  booking_form_supporting_field: "Facture (pdf)",
  booking_form_button_supporting: "Ajouter un justificatif",
  booking_form_button_supplier_bill: "Ajouter facture fournisseur",
  booking_form_button_payment: "Effectuer le règlement",
  booking_form_supplier_bill_field: "Date de facturation",
  booking_form_supplier_payment_field: "Date de règlement",
  booking_form_send_update_email_field: "Envoyer un mail de demande de modification",
  booking_form_update_email_content_field: "Contenu du mail",
  booking_form_cancel: "Annuler réservation",
  booking_form_keep: "Maintenir la réservation",

  // FILE TYPES
  file_type: "Type de fichier",
  file_ticket: "Billet",
  file_confirmation: "Confirmation",
  file_other: "Autre",
  TICKET: "Billet",
  CONFIRMATION: "Confirmation",
  OTHER: "Autre",
  BILL: "Facture",
  VOUCHER: "Voucher",
  PAYMENT: "Règlement",
  ORDER_ITEM_FILE: "Document de prestation",
  max_files: "Maximum 5 fichiers",
  max_files_count: "Maximum {max} fichiers",
  file_too_large: "Fichier trop volumineux (3 mo max par fichier)",

  // ACTIVITY BILLING
  menu_activity_billing: "Mes factures",
  activities_billing_title: "Mes factures",
  activities_billing_filter_from: "De",
  activities_billing_filter_to: "À",
  activities_billing_no_result: "Aucune prestations ne correspond aux filtres",
  activity_bill_date: "Date",
  activity_bill_name: "Nom de l'activité",
  activity_bill_customer: "Client",
  activity_bill_reference: "Référence client",
  activity_bill_activity_duration: "Durée",
  activity_bill_price: "Prix",
  activity_bill_bill_state: "Facturé",
  activity_bill_bill_number: "N° Facture",
  activity_bill_bill_date: "Date de facture",
  activity_billing_not_billed: "Non facturé",
  activity_billing_billed: "Facturé",
  activity_bill_get_error: "Erreur lors de la récupération des factures",
  activity_bill_create_success: "Merci pour la remise de la facture.\nCette dernière sera traitée dans les meilleurs délais.",
  activity_bill_create_error: "Erreur lors de la création des factures",
  activity_bill_create_error_message: "Erreur lors de la création des factures: {error}",
  activity_bill_results_number: "Nombre de visites sur la période",
  activity_bill_total_price: "Montant total",
  activity_bill_filter_all: "Toutes",
  activity_bill_filter_billed: "Facturées",
  activity_bill_filter_not_billed: "Non facturées",
  activity_bill_filter_header_paid: "Réglées",
  activity_bill_pay_button: "Régler les factures",
  activity_bill_accept_button: "Accepter les factures",
  activities_bill_status_update_toast_error: "Erreur lors du changement de statut de la facture",
  activities_bill_accept_toast_error: "Erreur lors de l'acceptation de la facture",
  activities_bill_accept_toast_success: "La facture a été acceptée avec succès",
  activity_bill_accept_toast_error: "Erreur lors de l'acceptation des factures.",
  activity_bill_accept_toast_success: "Les factures ont été acceptées avec succès",
  activity_bill_refused_toast_error: "Erreur lors du refus de la facture.",
  activity_bill_refused_toast_success: "Un mail a été envoyé à la ressource",
  activity_bill_pay_modal_tile: "Réglement de factures",
  activity_bill_pay_modal_body: "Voulez-vous régler {count} factures pour un montant de {amount}€ ?",
  activity_bill_pay_modal_action_success: "Les factures ont bien été réglées",
  activity_bill_pay_modal_action_failure: "Erreur lors du règlement des factures",
  activity_bill_download_failure: "Une erreur s'est produite lors du téléchargement du fichier",
  activity_bill_filter_header_to_check: "À vérifier",
  activity_bill_filter_header_unpaid: "À régler",
  activity_bill_create: "Ajouter une facture",
  activity_bill_amount: "Montant",
  activity_bill_amount_TTC: "Montant TTC",
  activity_bill_amount_HT: "Montant HT",
  activity_bill_export: "Exporter vers Excel",
  activity_billing_create: "Charger une facture",
  activity_billing_refused_email_greetings: "Bonjour {name},",
  activity_billing_refused_email_body_1: "Par le dépôt de ta facture sur Softouring le {date}, tu m’as fait parvenir la facture n° {number} d’un montant total de  {amount}€ relative à la période {period}.",
  activity_billing_refused_email_body_2: "Je viens de supprimer cette facture sur Softouring, cette dernière comportant une erreur sur XXXXXX (expliquez les raisons du refus avec autant de détails que possible).",
  activity_billing_refused_email_body_3: "Merci de vérifier cette facture et de corriger les éléments erronés. Puis de déposer la facture corrigée/revue sur Softouring",
  activity_billing_refused_email_bye: "Te remerciant et restant à disposition si besoin",
  activity_billing_refused_email_title: "Refuser",
  activity_billing_refused_email_field: "Contenu du mail",
  activity_billing_accept_modal_title: "Validation",
  activity_billing_accept_modal_multi_msg: "Voulez-vous accepter les {count} factures sélectionnées ?",
  activity_billing_accept_modal_msg: "La facture {number} passera à l'état acceptée",
  activity_billing_no_result: "Aucune facture correspondant à votre recherche n'a été trouvé",
  activity_billing_no_voucher_found: "Aucun voucher à télécharger",

  // ACTIVITY BILLING COMMENT
  activity_billing_comment_title: "Discussion facture {number}",
  activity_billing_comment_add: "Ajouter un commentaire",
  activity_billing_comment_date: "Date",
  activity_billing_comment_comment: "Commentaire",
  activity_billing_comment_from: "De",
  activity_billing_comment_at: "Le",
  activity_billing_comment_toast_error_get_comments: "Un problème est survenu lors de la récupération des anciens commentaires",
  activity_billing_comment_toast_error_send_comment: "Un problème est survenu lors de l'envoi du commentaire",
  activity_billing_comment_toast_success_send_comment: "Le commentaire a été ajouté, un mail a été envoyé au destinataire",
  activity_billing_comment_send: "Envoyer le commentaire",

  // ACTIVITY AREA
  activity_area_tab_title: "Zones d'activité",
  activity_area_label: "Nom",
  activity_area_no_result: "Aucune zone d'activité trouvée",
  activity_area_create: "Créer une zone d'activité",
  activity_area_edit: "Modifier une zone d'activité",
  success_toast_edit_activity_area: "Zone d'activité modifiée",
  error_toast_edit_activity_area: "Erreur lors de la modification de la zone d'activité",
  success_toast_create_activity_area: "Zone d'activité créée",
  error_toast_create_activity_area: "Erreur lors de la création de la zone d'activité",
  success_toast_delete_activity_area: "Zone d'activité supprimée",
  error_toast_delete_activity_area: "Erreur lors de la suppression de la zone d'activité",


  // RESSOURCE TYPE
  resource_type: "Type de ressource",
  resource_type_short: "Type",
  resource_type_tab_title: "Ressources",
  resource_type_label: "Nom",
  resource_type_no_result: "Aucun type de ressource trouvée",
  resource_type_create: "Créer un type de ressource",
  resource_type_edit: "Modifier un type de ressource",
  resource_type_color: "Couleur",
  success_toast_edit_resource_type: "Type de ressource modifié",
  error_toast_edit_resource_type: "Erreur lors de la modification du type de ressource",
  success_toast_create_resource_type: "Type de ressource créé",
  error_toast_create_resource_type: "Erreur lors de la création du type de ressource",
  success_toast_delete_resource_type: "Type de ressource supprimé",
  error_toast_delete_resource_type: "Erreur lors de la suppression du type de ressource",
  error_toast_fetch_resource_types: "Erreur lors de la récupération des types de ressource",
  activity_send_new_activity_mail_success: "Le mail a bien été envoyé",
  activity_send_new_activity_mail_error: "Une erreur est survenue lors de l'envoi du mail",


  // EXPORTS
  exports_tab_title: "Exports",
  exports_tab_summary: "Exporter le résumé des activités",
  exports_tab_supplier_billing: "Exporter les factures fournisseurs",
  exports_tab_summary_csv: "Exporter vers csv",
  exports_resource_services: "Services par guides vers csv",
  exports_purchased_services: "Réservations achetées vers csv",
  exports_tab_summary_pdf: "Exporter vers pdf",
  exports_activity_summary: "Résumé des activités vers pdf",
  exports_supplier_invoices: "Factures fournisseurs vers zip",
  exports_tab_summary_zip: "Exporter vers zip",
  exports_tab_services: "Exporter les services achetés",
  exports_error_no_services_found: "Aucun service trouvé",
  exports_error_too_many_services: "Trop de services sélectionnés",
  exports_error_pdf: "Erreur lors de la génération du PDF",

  // MONTHS
  month_january: "Janvier",
  month_february: "Février",
  month_march: "Mars",
  month_april: "Avril",
  month_may: "Mai",
  month_june: "Juin",
  month_july: "Juillet",
  month_august: "Août",
  month_september: "Septembre",
  month_october: "Octobre",
  month_november: "Novembre",
  month_december: "Décembre",
  label_january_abv: "Jan.",
  label_february_abv: "Fév.",
  label_march_abv: "Mars",
  label_april_abv: "Avril",
  label_may_abv: "Mai",
  label_june_abv: "Juin",
  label_july_abv: "Jui.",
  label_august_abv: "Août",
  label_september_abv: "Sep.",
  label_october_abv: "Oct.",
  label_november_abv: "Nov.",
  label_december_abv: "Déc.",

  // QUOTE
  quote_travel_book_button: "Exporter le carnet de voyage",
  quote_edit: "Modifier un devis",
  dropdown_header_quote: "Devis",
  create_new_quote: "Créer un devis",
  create_quote: "Créer un devis",
  quote_customer: "Client",
  quote_contact: "Email contact",
  quote_tour: "Tour",
  quote_reference_lient: "Référence client",
  quote_rendez_vous_date: "Date de début du tour",
  quote_date: "Date de demande",
  quote_pax: "PAX",
  quote_end_of_validity: "Date de fin de validité",
  success_toast_update_quote: "Devis mis à jour",
  success_toast_create_quote: "Devis créé",
  success_toast_update_quote_item: "Ligne de devis mise à jour avec succès",
  success_toast_create_quote_item: "Ligne de devis créée avec succès",
  quote_customer_ref: "Référence client",
  quote_code: "Numéro de devis",
  quote_nb_items: "Nb prestations",
  quote_total_ht: "Total HT",
  quote_status: "Statut",
  quote_state_draft: "Brouillon",
  quote_state_sent: "Envoyé",
  quote_state_win: "Gagné",
  quote_state_loose: "Perdu",
  quote_filter_all_requests: "Tous les devis",
  quote_filter_open: "Brouillon",
  quote_filter_to_handle: "Envoyé",
  quote_filter_to_win: "Gagné",
  quote_filter_to_loose: "Perdu",
  quotes_title: "Devis",
  quote_total_number: "{total} devis",
  quote_to_sent: "Envoyer le devis",
  quote_duplicate: "Dupliquer",
  quote_to_loose: "Devis perdu",
  quote_to_win: "Devis gagné",
  quote_to_draft: "Mettre en brouillon",
  quote_to_order: "Passer en commande",
  quote_download: "Télécharger le devis",
  quote_no_quote_item: "Aucune prestation",
  quote_add_quote_item: "Ajouter une prestation",
  quote_filter_code_title: "Rechercher un devis",
  error_toast_quote_update: "Erreur lors de la mise à jour du devis",
  quote_creation_date: "Date de création",
  quote_demand_date: "Date de demande",
  quote_date_end_of_validity: "Date de fin de validité",
  modal_body_delete_quote: "Êtes-vous sûr de vouloir supprimer ce devis ?",
  quote_day_zero: "Date de début du tour",
  quote_reference_client: "Référence client",
  quote_after_creation_date: "Ce champ doit être postérieur à la date de demande",
  quote_item_activity: "Prestation",
  quote_item_service: "Service",
  quote_item_name: "Nom",
  quote_item_optional: "Optionnel",
  quote_item_description: "Description",
  quote_item_place: "Lieu de rendez-vous",
  quote_item_date: "Date de rendez-vous",
  quote_item_hour: "Heure du rendez-vous",
  create_new_quote_item: "Ajout d'une ligne de devis",
  quote_item_pax: "PAX",
  quote_item_unit_type: "Type",
  quote_item_quantity: "Quantité",
  quote_item_pax_price: "Prix",
  quote_item_sell_price: "Prix de vente",
  quote_item_sell_price_tva: "TVA",
  quote_item_sell_price_total: "Prix total",
  quote_item_disbursements: "Débours",
  quote_send_mail: "Envoyer un mail au client avec le PDF en pièce jointe",
  quote_send_hello: "Bonjour {name}",
  quote_send_body_1: "Faisant suite à votre demande de devis, veuillez trouver ci-joint une proposition détaillée des prestations que vous souhaitez nous confier.",
  quote_send_body_2:"En complément d’informations, veuillez trouver la présentation détaillée ainsi que la plaquette de Paris on the Way",
  quote_send_body_3: "Pour validation de ce devis, merci de retourner par email un exemplaire dument signé, daté, et bon pour accord manuscrit. Pour les sociétés le tampon de la société est demandé.",
  quote_send_body_4: "Veuillez noter que ce devis est valable {endOdfValidity} jours.",
  quote_send_body_5: "Nous vous remercions d’avoir consultés Paris on the Way et restons à votre disposition pour toute information complémentaire.",
  quote_send_greetings: "Vous en souhaitant bonne réception, veuillez agréer, Madame/Monsieur, l’expression de mes salutations distinguées.",
  quote_send_pj: "Pièce(s) jointe(s) (2 max)",
  modal_body_send_title: "Envoyer le devis",
  modal_body_convert_title: "Passer en commande le devis",
  modal_body_send_quote: "Le devis sera considéré comme envoyé au client. Êtes-vous sûr de vouloir continuer ?",
  success_toast_duplicate_quote: "Devis dupliqué",
  error_toast_duplicate_quote: "Une erreur est survenue lors de la duplication",
  // RESOURCE AVAILABILITY
  menu_resource_availability:"Disponibilités",
  resource_availability_title:"Déclarer mes indisponibilités",
  error_get_resource_availability:"Erreur lors de la récupération des disponibilités",
  error_update_resource_availability:"Erreur lors de la mise à jour des disponibilités",
  success_update_resource_availability:"Disponibilités mises à jour",
  resource_availability_pm:"PM",
  resource_availability_am:"AM",
  resource_availability_night: "Nuit",
  resource_availability_unavailable:"Indisponible",
  resource_availability_available:"Disponible",
  resource_availability_staffed:"Staffé",
  resource_availability_filter_start:"De",
  resource_availability_filter_end:"A",
  resource_availability_mass_unavailable:"Valider indisponibilité",
  resource_availability_set_available:"Marquer disponible",
  resource_availability_set_unavailable:"Marquer indisponible",
  resource_availability_select_all: "Tout sélectionner",
  resource_availability_search: "Rechercher",
  resource_is_nocturnal: "De nuit",

  resource_calendar_SENT:"Demande de dispo envoyée",
  resource_calendar_REFUSED:"Demande refusée",
  resource_calendar_AVAILABLE:"Disponibilité confirmée",
  resource_calendar_CONFIRMED:"Staffé",
  resource_calendar_ALREADY_STAFFED:"Déjà staffé ailleurs",
  resource_calendar_UNAVAILABLE:"Indisponible",
  resource_calendar_FREE:"Dispo. Potentielle",
  resource_calendar_filter_activity_size:"Nombre d'activités",
  resource_calendar_filter_resource_size:"Nombre de ressources",

  view_calendar: "Vue calendrier",
  view_reporting: "Vue reporting",
  view_resource_calendar: "Calendrier staffing",
  menu_staffing_calendar: "Calendrier staffing",
  display_nightly: "Afficher créneau nocturne",
  work_on_current_year: "Total année en cours",
  work_on_last_year: "Total année précédente",
  availability_calendar: "Dispo ",
  header_count_activity: "prestations",
  header_count_staffing: "ressources",
  level_low_availability_calendar: "basse",
  level_medium_availability_calendar: "moyenne",
  level_high_availability_calendar: "élevée",

  calendar_day_panel_section_available_filtered: "Disponibilité confirmée",
  calendar_day_panel_section_available: "Disponibilité confirmée (hors filtre)",
  calendar_day_panel_section_staffable: "Prestation à staffer",
  calendar_day_panel_no_section: "Aucune prestation disponible à ce moment pour cette ressource",
  calendar_day_panel_to_staff: "A staffer x{number}",
  calendar_day_panel_staffing_confirm: "Confirmer",
  calendar_day_panel_staffing_ask_availability: "Demander disponibilité",
  calendar_day_panel_staffing_staff: "Staffer",
  calendar_day_panel_to_staff_tooltip_no_available: "Aucune ressource disponible",
  calendar_day_panel_staffing_send_availability: "Relancer",
  year: "Année",

  // SEASONS
  season_add_to_tour: "Ajouter une saison pour ce tour",
  season_form_name: "Nom de la saison",
  season_form_start_date: "Date de début de validité",
  season_creation_success: "Saison créée avec succès",
  season_fetch_failure: "Erreur lors de la récupération des saisons",
  season_creation_failure: "Erreur lors de la création de la saison",
  season_delete_title: "Supprimer une saison",
  season_delete_message: "Êtes-vous certain de vouloir supprimer la saison {season} ? Une saison ne pourra pas être supprimée si elle est référencée dans un catalogue client.",
  season_delete_success: "Saison supprimée avec succès",
  season_delete_failure: "Une erreur est survenue en essayant de supprimer la saison",
  season_for_catalog_tab_title: "Day by Day Client",
  season_start_validity_date: "Date de début de validité",
  season_customer_tour_name: "Nom du tour",
  season_pax_client: "PAX client",
  season_pax_activity: "PAX presta",
  season_update_day_by_day_form_title: "Client {customer} - {season}",
  season_update_tour_customer_name: "Nom du tour pour ce client",
  season_update_day_by_day_client_success: "Mise à jour du Day by Day Client avec succès",
  season_update_day_by_day_client_failure: "Une erreur est survenue lors de la mise à jour du Day by Day Client",
  season_delete_day_by_day_client_success: "Suppression du Day by Day Client avec succès",
  season_delete_day_by_day_client_failure: "Une erreur est survenue lors de la suppression du Day by Day Client",
  season_delete_day_by_day_client_title: "Suppression d'un Day by Day Client",
  season_delete_day_by_day_client_body: "Êtes-vous certain(e) de vouloir supprimer le Day By Day dy client {client} pour la saison {season}?",

  // CROPPER
  crop_modal_title: "Rogner l'image",
  crop_image: "Confirmer",

  // PICTURES
  picture_title: "Image",
  picture_modal_title: "Supprimer la photo",
  picture_modal_body_tour: "Voulez-vous supprimer la photo du tour ?",
  picture_modal_body_client: "Voulez-vous supprimer le logo du client ?",
  picture_modal_body_activity: "Voulez-vous supprimer la photo de la prestation ?",
  picture_modify_tour: "Veuillez ajouter une photo aux dimensions 400x200 pour un rendu optimal. Les formats autorisés sont png, jpg, jpeg, gif, svg",
  picture_modify_client: "Les formats autorisés sont png, jpg, jpeg, gif, svg",
  picture_modify_activity: "Veuillez ajouter une photo aux dimensions 400*200 pour un rendu optimal. Les formats autorisés sont png, jpg, jpeg, gif, svg",
  picture_creation_success:"Image ajoutée avec succès",
  picture_deletion_success:"Image supprimée avec succès",

  // BLACK DATE
  black_date_error_create: "Une erreur est survenue lors de la creation des black dates",
  black_date_created: "Les dates ont étés ajoutées",
  black_date_one_date: "Jour précis",
  black_date_recurrence: "Récurrence",
  black_date_tab: "Black dates",
  each_monday: "Tous les lundis",
  each_tuesday: "Tous les mardis",
  each_wednesday: "Tous les mercredis",
  each_thursday: "Tous les jeudis",
  each_friday: "Tous les vendredis",
  each_saturday: "Tous les samedis",
  each_sunday: "Tous les dimanches",
  no_supplier_available: "Aucun fournisseur ne peut proposer le(s) service(s) associées à cette prestation à cette date",

  // ACTIVITY RESOURCE REQUIREMENTS
  activity_resource_requirement_no_result: "Aucune ressource requise pour effectuer cette prestation",
  activity_resource_requirement_quantity: "Nombre de ressources requises",
  activity_resource_requirement_unit_price: "Tarif unitaire (HT)",
  activity_resource_requirement_create: "Ajouter un type de ressource requise",
  activity_resource_requirement_edit: "Modifier un type de ressource requise",
  activity_resource_requirement_edit_success: "Type de ressource requise modifié avec succès",
  activity_resource_requirement_edit_error: "Erreur lors de la mise à jour du type de ressource requise",
  activity_resource_requirement_create_success: "Type de ressource requise créé avec succès",
  activity_resource_requirement_create_error: "Erreur lors de la création du type de ressource requise",
  activity_resource_requirement_delete_body: "Voulez-vous supprimer le type de ressource requise ?",
  activity_resource_requirement_delete_success: "Type de ressource requise supprimé avec succès",
  activity_resource_requirement_delete_error: "Erreur lors de la suppression du type de ressource requise",

  //ROAD BOOK
  road_book_download_pdf: "Exporter le carnet de voyage",
  road_book_title: "Itinéraire journalier",
  road_book_from: "Du",
  road_book_to: "Au",
  road_book_pax_tbc: "PAX : {pax} (TBC)",
  road_book_item_title: "Jour {index} -",
  road_book_modal_ore_details: "Plus de détails >",

  // VAT
  vat_tab_title: "Taux TVA",
  vat_create: "Créer une TVA",
  vat_edit: "Editer une TVA",
  vat_rate: "Taux (%)",
  vat_rate_table: "Taux",
  vat_name: "Intitulé",
  vat_rate_no_result: "Il n'y a pas de TVA",
  vat_rate_toast_create_success: "Le taux de TVA a été crée",
  vat_rate_toast_create_error: "Erreur lors de la création du taux de TVA",
  vat_rate_toast_edit_success: "Le taux de TVA a été modifié",
  vat_rate_toast_edit_error: "Erreur lors de la modification du taux de TVA",
  vat_rate_toast_delete_success: "Le taux de TVA a été supprimé",
  vat_rate_toast_delete_error: "Erreur lors de la suppression ce taux de TVA est utilisé par un service",
  vat_rate_toast_read_error: "Erreur lors de la récupération des taux de TVA",

  // Accounting Account
  accounting_config_tab_title: "Comptes comptable",
  accounting_config_fr: "N° Cpte ressource FR",
  accounting_config_eu: "N° Cpte ressource UE",
  accounting_config_not_ue: "N° Cpte ressource Hors UE",
  accounting_config_tva: "N° Cpte TVA déductible",
  accounting_config_purchase: "N° Cpte achat prestation",

  // Activity billing payment
  payment_title: "Mes réglements",
  payment_sepa_file: "Fichier sepa",
  payment_compta_file: "Fichier comptable",
  payment_invoices_file: "Factures",
  payment_action: "Téléchargement",
  payment_navigate_btn: "Accéder aux réglements",
  payment_table_number: "N° réglement",
  payment_table_creation_date: "Date de réglement",
  payment_table_activity_date: "Date de prestation",
  payment_table_count: "Nb factures",
  payment_table_amount: "Montant total (€)",

  // INVOICE PAYMENT
  invoice_payment: "Suivi des règlements",
  invoice_payment_title: "Réglements",
  invoice_payment_create: "Saisir un règlement",
  invoice_payment_search: "Recherche un règlement",
  invoice_payment_from_credit_note_filter: "Uniquement les règlements par avoir",
  invoice_payment_search_placeholder: "N° référence",
  invoice_payment_search_date: "Date de réglement",
  invoice_payment_reference_table: "Référence",
  invoice_payment_date_table: "Date de réception du paiement",
  invoice_payment_customer_table: "Client",
  invoice_payment_amount_table: "Montant réglé",
  invoice_payment_action_table: "Action",
  invoice_payment_no_result: "Aucun paiement trouvé",
  invoice_payment_price_ht: "Montant HT",
  invoice_payment_price_ttc: "Montant TTC",
  invoice_payment_paid: "Réglé",
  invoice_payment_remaining: "Restant dû",
  invoice_payment_total_paid: "Total réglé",
  invoice_payment_total_remaining: "Total restant",
  invoice_payment_invoice: "Facture",

  // INVOICE PAYMENT CREATE
  invoice_payment_create_page_title: "Saisir un règlement",
  invoice_payment_create_add_title: "Factures et lignes de factures à régler",
  invoice_payment_create_add_subtitle: "Choisir les lignes de facture dans la liste ci-dessous",
  invoice_payment_create_added_title: "Contenue du règlement",
  invoice_payment_create_added_subtitle: "Lignes qui seront ajoutées au réglement",
  invoice_payment_create_header_name: "Nom",
  invoice_payment_create_header_amount_to_paid: "Montant à régler",
  invoice_payment_create_header_amount_remaining: "Montant restant dû",
  invoice_payment_create_header_amount_initial: "Montant initial",
  invoice_payment_create_invoice_count: "Facture",
  invoice_payment_create_invoice_total: "Total",
  invoice_payment_create_toast_success: "Le payment a bien été créé",
  invoice_payment_create_toast_error: "Erreur lors de la création du payment",
  invoice_payment_create_toast_get_unpaid_error: "Erreur lors de la récupération des lignes de factures impayées",
  invoice_payment_create_payment_date: "Date de règlement",
  invoice_payment_create_reference_number: "Référence de paiement",
  invoice_payment_create_reference_number_placeholder: "RG_XXXX",
};

export default FrenchMessages;
