import {
  Customer,
  CustomerBillingService,
  CustomerFields,
  CustomerIdentity,
  CustomerSearchObject,
  CustomerWithTour,
  InvoiceOperationCustomerFields, RemindInvoiceCustomerMailBody
} from "../interfaces/CustomerInterfaces";
import {httpService} from "./HttpService";
import {API_CUSTOMERS_PATH} from "../constants/routes/RoutePaths";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {Comment, CommentRequest} from "../interfaces/CommentInterface";
import {queryUtils} from "../utils/queryUtils";
import {WorkflowStatesEnum} from "../constants/workflow/WorkflowStates";
import {CustomerOrderItemSearchObject, OrderItem} from "../interfaces/OrderItemInterfaces";
import {OrderList, OrderSearchObject} from "../interfaces/OrderInterfaces";
import {TRUE_FALSE_ENUM} from "../constants/OptionConstants";

function getCustomersPage (params: PaginationQueryParams, filter: CustomerSearchObject): Promise<PageResponse<Customer>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filter)
  return httpService.get<PageResponse<Customer>>(`${API_CUSTOMERS_PATH}/page${queryParams}`)
}

function getSelfCustomersOrderItemPage (params: PaginationQueryParams, filter: CustomerOrderItemSearchObject): Promise<PageResponse<OrderItem>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filter)
  return httpService.get(`${API_CUSTOMERS_PATH}/self/order-items${queryParams}`)
}

function getCustomersOrderPage (params: PaginationQueryParams, filter: OrderSearchObject): Promise<PageResponse<OrderList>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filter)
  return httpService.get(`${API_CUSTOMERS_PATH}/self/orders${queryParams}`)
}

function getActiveCustomers (params: PaginationQueryParams, filter?: CustomerSearchObject): Promise<PageResponse<Customer>> {
  filter = {
    ...filter,
    currentState: WorkflowStatesEnum.ACTIVE
  }
  return getCustomersPage(params, filter);
}

function createCustomer (customerRequest: Customer): Promise<Customer> {
  return httpService.post<Customer>(API_CUSTOMERS_PATH, customerRequest)
}

function updateCustomer (id: string, customerRequest: Customer): Promise<Customer> {
  return httpService.put<Customer>(`${API_CUSTOMERS_PATH}/${id}`, customerRequest)
}

function getCustomerById(id: string): Promise<Customer> {
  return httpService.get<Customer>(`${API_CUSTOMERS_PATH}/${id}`)
}

function getCustomerIdentityByCustomerId(id: string): Promise<CustomerIdentity> {
  return httpService.get(`${API_CUSTOMERS_PATH}/${id}/identity`)
}

function deleteCustomer (id: string): Promise<Response> {
  return httpService.delete(`${API_CUSTOMERS_PATH}/${id}`)
}

function getCustomerWithTours (): Promise<CustomerWithTour[]> {
  return httpService.get<Customer[]>(`${API_CUSTOMERS_PATH}/tours`)
}

const createCustomerComment = (customerId: string, commentRequest: CommentRequest): Promise<Comment> => {
  return httpService.post<Comment>(`${API_CUSTOMERS_PATH}/${customerId}/comments`, commentRequest);
}

const updateCustomerComment = (customerId: string, commentRequest: CommentRequest): Promise<Comment> => {
  return httpService.put<Comment>(`${API_CUSTOMERS_PATH}/${customerId}/comments`, commentRequest);
}

function deleteCommentForCustomer (id: string): Promise<Response> {
  return httpService.delete(`${API_CUSTOMERS_PATH}/${id}/comments`)
}

const remindInvoiceCustomerMail = (customerId: string, body: RemindInvoiceCustomerMailBody): Promise<Response> => {
  return httpService.post(`${API_CUSTOMERS_PATH}/${customerId}/reminder`, body);
}

function buildCustomerRequest (customerFields: CustomerFields, original: Customer): Customer {
  return {
    ...original,
    name: customerFields?.name,
    code: customerFields?.code,
    paymentCondition: customerFields?.paymentCondition,
    address: {
      mainLabel: customerFields?.mainLabel,
      additionalInformation: customerFields?.additionalInformation,
      service: customerFields?.service,
      zipCode: customerFields?.zipCode,
      city: customerFields?.city,
      country: customerFields?.country,
      latitude: customerFields?.latitude,
      longitude: customerFields?.longitude
    }
  }
}

function buildCustomerFields (customer: Customer): CustomerFields {
  if (!customer) return undefined;

  return {
    id: customer?.id,
    name: customer?.name,
    code: customer?.code,
    mainLabel: customer?.address?.mainLabel,
    additionalInformation: customer?.address?.additionalInformation,
    service: customer?.address?.service,
    zipCode: customer?.address?.zipCode,
    city: customer?.address?.city,
    country: customer?.address?.country,
    latitude: customer?.address?.latitude,
    longitude: customer?.address?.longitude,
    currentState: customer.currentState,
    paymentCondition: customer.paymentCondition,
  }
}

function buildInvoiceOperationRequest (
  invoiceOperationFields: InvoiceOperationCustomerFields,
  customer: Customer
): Customer {
  const billingServices: CustomerBillingService[] = invoiceOperationFields?.billingServices.map(billingService => ({
    customerId: customer.id,
    billingServiceId: billingService.value,
    isDefault: invoiceOperationFields.defaultBillingServiceId === billingService.value
  }))
  const invoiceOperation = {
    ...invoiceOperationFields,
    individual: invoiceOperationFields.individual === TRUE_FALSE_ENUM.TRUE,
    invoiceForCustomer: invoiceOperationFields.invoiceForCustomer === TRUE_FALSE_ENUM.TRUE,
    disbursement: invoiceOperationFields.disbursement === TRUE_FALSE_ENUM.TRUE,
    isVoucherManagement: invoiceOperationFields.isVoucherManagement === TRUE_FALSE_ENUM.TRUE,
    isReservationManagement: invoiceOperationFields.isReservationManagement === TRUE_FALSE_ENUM.TRUE,
    notificationForActivity: invoiceOperationFields.notificationForActivity === TRUE_FALSE_ENUM.TRUE,
    notificationForReplacement: invoiceOperationFields.notificationForReplacement === TRUE_FALSE_ENUM.TRUE
  }
  return {
    ...customer,
    ...invoiceOperation,
    billingServices: billingServices
  }
}

function buildInvoiceOperationFields (customer: Customer): InvoiceOperationCustomerFields {
  if (!customer) return undefined;

  return {
    individual: customer.individual?.toString(),
    geographicalZone: customer.geographicalZone,
    numberIntraVAT: customer.numberIntraVAT,
    invoiceForCustomer: customer.invoiceForCustomer?.toString(),
    billingServices: customer.billingServicesAsCustomers.map(billingService => ({
      value: billingService.id,
      label: `${billingService.code} - ${billingService.name}`
    })),
    defaultBillingServiceId: customer.defaultBillingServiceAsCustomer?.id,
    disbursement: customer.disbursement?.toString(),
    isVoucherManagement: customer.isVoucherManagement?.toString(),
    mainSortInvoice: customer.mainSortInvoice,
    secondarySortInvoice: customer.secondarySortInvoice,
    isReservationManagement: customer.isReservationManagement?.toString(),
    numberWeeksViewed: customer.numberWeeksViewed,
    notificationForActivity: customer.notificationForActivity?.toString(),
    notificationForReplacement: customer.notificationForReplacement?.toString(),
    publicType: customer.publicType,
    paymentCondition: customer.paymentCondition,
    siret: customer.siret
  }
}

export const customerService = {
  getCustomersPage,
  getSelfCustomersOrderItemPage,
  getCustomersOrderPage,
  getActiveCustomers,
  createCustomer,
  updateCustomer,
  getCustomerById,
  deleteCustomer,
  getCustomerWithTours,
  createCustomerComment,
  updateCustomerComment,
  deleteCommentForCustomer,
  buildCustomerRequest,
  buildCustomerFields,
  buildInvoiceOperationRequest,
  buildInvoiceOperationFields,
  getCustomerIdentityByCustomerId,
  remindInvoiceCustomerMail,
}
