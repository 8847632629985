import {httpService} from "./HttpService";
import {API_INVOICES_PATH} from "../constants/routes/RoutePaths";
import {
  BillingFormData,
  Invoice,
  InvoiceFormData,
  InvoiceItemResponse,
  InvoiceSearchObject, InvoiceVatMarginSearchObject
} from "../interfaces/InvoiceInterfaces";
import {PageResponse, PaginationQueryParams} from "../interfaces/api/PaginationInterface";
import {queryUtils} from "../utils/queryUtils";
import {dateUtils} from "../utils/dateUtils";

function getInvoices(params?: PaginationQueryParams, filter?: InvoiceSearchObject): Promise<PageResponse<InvoiceItemResponse>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filter)
  return httpService.get<PageResponse<InvoiceItemResponse>>(`${API_INVOICES_PATH}${queryParams}`);
}

function getSelfInvoices(params?: PaginationQueryParams, filter?: InvoiceSearchObject): Promise<PageResponse<InvoiceItemResponse>> {
  const queryParams = queryUtils.buildPageAndFilterQueryParams(params, filter)
  return httpService.get<PageResponse<InvoiceItemResponse>>(`${API_INVOICES_PATH}/self${queryParams}`);
}

function getInvoice(id: string): Promise<Invoice> {
  return httpService.get<Invoice>(`${API_INVOICES_PATH}/${id}`);
}

function createInvoice(orderItemsIds: Array<string>): Promise<Invoice[]> {
  return httpService.post<Invoice[]>(`${API_INVOICES_PATH}/pre-invoice`, {
    orderItemsIds,
    date: dateUtils.today(),
    preInvoice: true
  });
}

function createManualInvoice(formData: InvoiceFormData): Promise<InvoiceItemResponse> {
  return httpService.post<InvoiceItemResponse>(`${API_INVOICES_PATH}`, formData);
}

function addBillingItem(id: string, formData: BillingFormData): Promise<InvoiceItemResponse> {
  return httpService.post<InvoiceItemResponse>(`${API_INVOICES_PATH}/${id}/billing-items`, formData);
}

function updateBillingItem(id: string, idBilling: string, formData: BillingFormData): Promise<InvoiceItemResponse> {
  return httpService.post<InvoiceItemResponse>(`${API_INVOICES_PATH}/${id}/billing-items/${idBilling}`, formData);
}

function deleteBillingItem(id: string, idBilling: string): Promise<Response> {
  return httpService.delete(`${API_INVOICES_PATH}/${id}/billing-items/${idBilling}`);
}

function updateComment(id: string, content: string): Promise<Invoice> {
  return httpService.post<Invoice>(`${API_INVOICES_PATH}/${id}/comments`, {content});
}

function updateContact(id: string, contactId: string | null): Promise<Invoice> {
  return httpService.put<Invoice>(`${API_INVOICES_PATH}/${id}/contact`, contactId);
}

function deleteComment(id: string): Promise<Response> {
  return httpService.delete(`${API_INVOICES_PATH}/${id}/comments`, {});
}

function deleteInvoice(id: string): Promise<Response> {
  return httpService.delete(`${API_INVOICES_PATH}/${id}`, {});
}

const downloadInvoices = (invoiceId: string): Promise<Blob> => {
  return httpService.getReadableStream(`${API_INVOICES_PATH}/${invoiceId}/download`);
}

const downloadInvoiceReminder = (invoiceId: string): Promise<Blob> => {
  return httpService.getReadableStream(`${API_INVOICES_PATH}/${invoiceId}/reminder/download`);
}

function manageTransition(invoiceId: string): Promise<Invoice> {
  return httpService.post<Invoice>(`${API_INVOICES_PATH}/${invoiceId}/transition`, {});
}

const downloadVatOnMarginCsv = (filter: InvoiceVatMarginSearchObject): Promise<Blob> => {
  const pathParams = queryUtils.buildPageAndFilterQueryParams(null, filter)
  return httpService.getReadableStream(`${API_INVOICES_PATH}/export${pathParams}`);
}

const downloadLinkedVouchers = (invoiceId: string): Promise<Blob> => {
  return httpService.getReadableStream(`${API_INVOICES_PATH}/${invoiceId}/vouchers`);
}

const showToCustomer = (invoiceId: string, showToCustomer: boolean): Promise<Invoice> => {
  return httpService.put<Invoice>(`${API_INVOICES_PATH}/${invoiceId}/show-to-customer`, showToCustomer);
}

export const invoiceService = Object.freeze({
  getInvoices,
  deleteBillingItem,
  updateBillingItem,
  getInvoice,
  updateComment,
  deleteComment,
  manageTransition,
  createInvoice,
  createManualInvoice,
  downloadInvoices,
  downloadInvoiceReminder,
  addBillingItem,
  deleteInvoice,
  getSelfInvoices,
  downloadVatOnMarginCsv,
  updateContact,
  downloadLinkedVouchers,
  showToCustomer
});
